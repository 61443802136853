import React, { Component } from 'react';
import './services-page.scss';
import { Link, Route } from 'react-router-dom';
import { Button, Menu, Segment } from 'semantic-ui-react';

import ServicesDescription from './services-page-components/services-page-description'
import GenomeSeqTab from './services-page-components/services-page-genomeSeqTab';
import NGSeqTab from './services-page-components/services-page-ngSeqTab';
import BioinfTab from './services-page-components/services-page-bioinformaticsTab';
import RNATab from './services-page-components/services-page-rnaSeqTab';

class ServicesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      showsDescription: true
    }
  }

  componentDidMount() {
    if(window.location.pathname === '/services/description') {
      this.setState({showsDescription: false})
    } else  {
      this.setState({showsDescription: true})
    }
  }

  componentWillReceiveProps(props) {
    try {
      this.setState({activeTab: props.location.state.activeTab, showsDescription: props.location.state.showsDescription})
    } catch {
      //console.log('no props from this Route')
    }
  }

  handleTabChange = (e, {name}) => {
    this.setState({ activeTab: name })
  } 

  render() {
    const { activeTab } = this.state
    const { showsDescription } = this.state
    return (
      <div className="ServicesPage">
        <div className='App-ColumnContainer'>
              {showsDescription ?
                <Menu pointing secondary>
                <Menu.Item as={ Link } to={`${this.props.match.url}/rna-seq`} name='RNA-Seq' active={activeTab === 'RNA-Seq'} onClick={this.handleTabChange}>
                  RNA-Seq
                </Menu.Item>
                <Menu.Item as={ Link } to={`${this.props.match.url}/genome-sequencing`} name='Genome Sequencing' active={activeTab === 'Genome Sequencing'} onClick={this.handleTabChange}>
                  Genome Sequencing
                </Menu.Item>
                <Menu.Item as={ Link } to={`${this.props.match.url}/ngs`} name='NGS' active={activeTab === 'NGS'} onClick={this.handleTabChange}>
                  NGS
                </Menu.Item>
                <Menu.Item as={ Link } to={`${this.props.match.url}/bioinformatics`} name='Bioinformatics' active={activeTab === 'Bioinformatics'} onClick={this.handleTabChange}>
                  Bioinformatics
                </Menu.Item>
              </Menu> : <div></div>}           
            
            <Segment basic>
              <div>
                <Route path={`${this.props.match.path}/description`} component={ServicesDescription}/>  
                <Route path={`${this.props.match.path}/genome-sequencing`} component={GenomeSeqTab}/>
                <Route path={`${this.props.match.path}/ngs`} component={NGSeqTab}/>
                <Route path={`${this.props.match.path}/bioinformatics`} component={BioinfTab}/>
                <Route path={`${this.props.match.path}/rna-seq`} component={RNATab}/>
              </div>
            </Segment>
            <div className='ServicesPage-Contact'>
              <span>for more information</span>
              <Button as={Link} to='/contact'>Contact Us</Button>
            </div> 
        </div>
      </div>
    );
  }
}

export default ServicesPage;