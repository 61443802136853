import React, {Component} from "react";
import './navBar.scss';
import logo from '../../content/img/banner.png';
import { Menu, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeItem: ''
        };
    }

    handleNavigationClick = (e, { name }) => 
        this.setState({ activeItem: name });

    handleHomeClick = (e) => this.setState({activeItem:''})

    /*shouldComponentUpdate(props) {
        if(props != null) {
            try {
                this.setState({ activeItem: props.location.state.activeItem })
              } catch {
                //console.log('no props from this Route')
              }
        }
      } */

    render() {
        const { activeItem } = this.state
        const serverLink = 'https://192.68.213.25:4443/WebInterface/login.html'
        return (
            <div className='NavBar'>
                <div className='NavBar-Logo'>
                    <Link to='/home'><img src={logo} className='Navbar-Logo-Img' onClick={this.handleHomeClick} alt=""/> <div className="Navbar-Logo-Liquidation">i. L.</div></Link>
                </div>
                <div className='NavBar-RightContainer'>
                    {/* <div className='NavBar-Login'>
                        <a href={serverLink} rel="noopener noreferrer" target='_blank'>
                        <Button basic icon='sign-in' content='Login'/></a>
                    </div> */}
                    <div className='NavBar-Navigator'>
                        <Menu secondary size='large'>
                            <Menu.Item
                            as={ Link }
                            to='/home'
                            name='Home'
                            active={activeItem === 'Home'}
                            onClick={this.handleNavigationClick}/>
                            {/* <Menu.Item
                            as={ Link }
                            to = {{
                                pathname: '/services/description',
                                state: {activeTab: '', showsDescription: false}
                                }}
                            name='Services'
                            active={activeItem === 'Services'}
                            onClick={this.handleNavigationClick}/>
                            <Menu.Item
                            as={ Link }
                            to={{
                                pathname: '/technology/description',
                                state: {activeTab: '', showsDescription: false}
                                }}
                            name='Technology'
                            active={activeItem === 'Technology'}
                            onClick={this.handleNavigationClick}/>
                            <Menu.Item
                            as={ Link }
                            to='/company'
                            name='Company'
                            active={activeItem === 'Company'}
                            onClick={this.handleNavigationClick}/>
                            <Menu.Item
                            as={ Link }
                            to='/support'
                            name='Support'
                            active={activeItem === 'Support'}
                            onClick={this.handleNavigationClick}/> */}
                            <Menu.Item
                            as={ Link }
                            to='/contact'
                            name='Contact'
                            active={activeItem === 'Contact'}
                            onClick={this.handleNavigationClick}/>
                        </Menu>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavBar;