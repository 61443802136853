import React from 'react';
import { List, Divider } from 'semantic-ui-react'

const ShotgunSeq = () =>
<div>
    <h3>Metagenome and 16S sequencing</h3>
    <Divider/>
    <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
        <p><b>Metagenome sequencing</b><br/>
        The service includes:</p>
        <List bulleted>
            <List.Item>
            Isolation of community genomic DNA
            </List.Item>
            <List.Item>
            Preparation and Illumina sequencing of shotgun libraries from environmental gDNA
            </List.Item>
            <List.Item>
            Bioinformatics: taxonomic classification of metagenomic data
            </List.Item>
        </List>
        
        <p><b>16S sequencing</b><br/>Besides established strategies for 16S rRNA gene profiling, like V1-V3 16S rRNA amplicon. Sequencing with the Illumina MiSeq system, we offer Nanopore sequencing of 16S full length sequences. The approaches robustly characterizes the taxonomic composition of microbial communities.</p>
        </div>
    </div>
</div>

export default ShotgunSeq;