import React, { Component } from "react";
import { Icon, Divider, Accordion, Image } from 'semantic-ui-react';

import PARE_IMG from '../../../../../content/img/PARE_Libraries.png';

class PARE extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
            <h3>Parallel Analysis of RNA Ends (PARE)</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>For high-throughput identification of microRNA (miRNA) targets (degradome analysis), as described by German et al. 2009, NATURE PROTOCOLS, VOL.4 NO.3.</p>
                    <p>We have adapted the method to the new Illumina sequencing systems and it works very efficiently and reliable in our hands.</p>
                    <p>We offer two approaches, the <b>classical</b> and a <b>modified</b> protocol.</p>
                    <p>The <b>modified method</b> also starts with the ligation of the 5‘ sequencing adapter to the 5‘P ends of degraded RNA. But then, longer 5‘ cDNA fragments a prepared from the ligated RNA molecules, allowing longer read lengths through the degradation sites. This will greatly facilitate the identification of the RNA degradation sites.</p>
                </div>
                <div className='App-ColumnContainer'>
                    <h4>Example: Preparation of classical PARE cDNA from Nicotiana</h4>
                    <p className='App-TextBox'>From 10 µg of the total RNA from Nicotiana spec. (Fig. 1, panel A), poly(A)+ RNA was isolated which was used for cDNA synthesis.</p>
                    <div className='App-ImageContainer'><Image src={PARE_IMG}/></div>
                    <div className='App-TextBox'>
                        <p>First, an RNA adapter (modified Solexa Gex 1 adapter) was ligated to the 5'-phosphate of processed mRNA. First-strand cDNA synthesis was primed with an oligo-dT adapter primer and the cDNA was amplified with PCR in 12 cycles (Fig.1, panel B).</p>
                        <p>The cDNA was further treated with Mme I, which cuts 20 bp downstream of its recognition site TCCRAC, located at the 3‘ end of the ligated Solexa Gex 1 adapter. To the released 51 bp long Mme I fragments, the Illumina 3' TruSeq adapter was ligated. The ligation products were purified and PCR amplified in 5 cycles (Fig 1, panel C).</p>
                        <p>The PARE cDNA was paired-end sequenced on an Illumina NextSeq 500 System using 2x25 bp read length.</p>
                    </div>
                </div>
            </div>
            <Divider/>
            <Accordion>
                <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                <Icon name='dropdown' />
                <b>References</b>
                </Accordion.Title>
                <Accordion.Content active={activeRef === 1}>
                <div className='App-References'>
                <p>Surbanovski, Nada, et al. "A highly specific microRNA-mediated mechanism silences LTR retrotransposons of strawberry." <i>The Plant Journal</i> 85.1 (2016): 70-82.</p>
                </div>
                </Accordion.Content>
            </Accordion>
        </div>
        );
    }
}

export default PARE;