import React, { Component } from "react";
import { Divider, Image, List } from 'semantic-ui-react';

import TAGRNA_SEQ_IMG from '../../../../../content/img/tagRNA-seq_schema.png'
import TAGRNA_SEQWT_IMG from '../../../../../content/img/tagRNA-seqWT_schema.png'
import TAGRNA_CYANOBACT_IMG from '../../../../../content/img/tagRNA-seq-cyanobact.png'

class TagRNASeq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
            <h3>tagRNA-seq analysis of the transcriptional organization of a bacterium</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>tagRNA-seq is a modified RNA-seq method which is based on the differential labelling of 5' RNA ends. It enables to discriminate primary from processed 5' RNA ends. The method is described in detail by Innocenti, N. et al. 2015. RNA (New York, N.Y.). 21, 5 (May 2015), 1018–30</p>
                    <p>It is based on specific ligation of distinct sequence tags to <b>processed start sites (PSS)</b> and <b>transcription start sites (TSS)</b>, thereby gaining insight into regulatory processes at transcriptional and post-transcriptional levels.</p>
                    <p>We offer two variants of the method, i) with focus on identification of TSS and PSS, and ii) whole transcriptome sequencing including information on TSS and PSS. The two methods are outlined in the schemas below.</p>
                </div>
                <h4>A)  Original tagRNA-seq method:</h4>
                <Image className='Trans-ImgContainer' src={TAGRNA_SEQ_IMG}/>
                <h4>B) Wohle transcriptome sequencing including targeting of TSS and PSS:</h4>
                <Image className='Trans-ImgContainer' src={TAGRNA_SEQWT_IMG}/>
        
                <div className='App-TextBox'>
                <List bulleted>
                    <p><b>The following steps are carried out during tagRNA-seq cDNA library preparation:</b></p>
                    <List.Item>Removal of rRNA molecules</List.Item>
                    <List.Item>Ligation of tagged RNA adapter to 5' monphosphorylated RNA (labelling PSS)</List.Item>
                    <List.Item>Enzymatic conversion of 5' triphosphate (5'PPP) to 5' monophosphate (5'P)</List.Item>
                    <List.Item>Ligation of second RNA adapter carrying sequence tag2 to the formed 5'P of primary transcripts (labelling of TSS)</List.Item>
                </List>
                </div>

                <h3>Advantage of method</h3>
                <div className='App-TextBox'>
                    <p>With dRNA-seq and Cappable-seq not all transcripts can be detected in a single experiment as 5' monphosphorylated RNA is either degraded (dRNA-seq) or removed (Cappable-seq), and thus information on post-transcriptional events is lost</p>
                    <p>tagRNA-seq represents a comprehensive all in one method for the <b>qualitative and quantitative</b> analysis of the transcriptional organization of a bacterium.</p>
                </div>
                <div className='App-ColumnContainer'>
                    <h3>Example</h3>
                    <p className='App-TextBox'>Mapping of sequence reads obtained from tagRNA-seq cDNA library: TSSs upper panel, PSSs lower panel.</p>
                    <div className='App-ImageFont'>
                    <span>Cyanobacterium spec.</span>
                    <Image src={TAGRNA_CYANOBACT_IMG}/>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default TagRNASeq;