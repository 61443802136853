import React, { Component } from "react";
import { Divider } from 'semantic-ui-react';

class DualRNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
            <h3>Dual RNA-seq</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>For quantification of mixed transcriptomes, e.g. RNA-seq analysis of host-pathogen interactions.</p>
                    <p>The method was established by Prof. Westermann and Prof. Vogel in Würzburg (for review see Westermann, A.J. and Vogel, J., 2018. Host-pathogen transcriptomics by Dual RNA-Seq. In <i>Bacterial Regulatory RNA</i> (pp. 59-75). Humana Press, New York, NY.)</p>
                    <p>In close cooperation with the authors we were able to further improve and optimize the method and we can  offer a highly up-to-date Dual RNA-seq service to our customers.</p>
                </div>       
            </div>
        </div>
        );
    }
}

export default DualRNA;