import React, { Component } from "react";
import {  Divider, List } from 'semantic-ui-react';
import {Link} from 'react-router-dom'

class SingleCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
                <h3>Single cell RNA Sequencing</h3>
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                        <p>Our highly sensitive cDNA synthesis methods allow RNA-seq in single cell resolution.</p>
                        <p>For eukaryotic cells we use the following steps for synthesis of strand specific cDNA:</p>
                        <div className='App-TextBox'>
                            <List bulleted>
                                <List.Item>
                                Synthesis of oligo(dT) primed 1.-strand cDNA from total RNA
                                </List.Item>
                                <List.Item>
                                Fragmentation and purification of 1.-strand cDNA
                                </List.Item>
                                <List.Item>
                                Strand specific ligation of sequencing adapters to the 3’ and 5’ ends of the first-strand cDNA
                                </List.Item>
                                <List.Item>
                                PCR amplification of cDNA
                                </List.Item>
                            </List>
                        </div>
                        <br/>
                        <p>With the use of <Link to={{
                            pathname:'/technology/rna-seq/special/quantitative-rna-seq', 
                            state:{activeTab: 'RNA-Seq', activeTopic: 'QuantitativeRNA'}}}>Unique Molecular Identifiers(UMIs)</Link>, amplification noise can be removed from the sequence data leading to robust differential expression profiles also in single cell experiments.</p>
                    </div>
                </div>
                {/*<Divider/>
                <Accordion>
                    <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                    <Icon name='dropdown' />
                    <b>References</b>
                    </Accordion.Title>
                    <Accordion.Content active={activeRef === 1}>
                    <div className='App-References'>
                    <p>Surbanovski, Nada, et al. "A highly specific microRNA-mediated mechanism silences LTR retrotransposons of strawberry." <i>The Plant Journal</i> 85.1 (2016): 70-82.</p>
                    </div>
                    </Accordion.Content>
                </Accordion>*/}
            </div>
        );
    }
}

export default SingleCell;