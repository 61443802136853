import React from 'react';
import ImageButton from '../../components/imageButton/imageButton'

import GenomeSequencingIMG from '../../content/img/Genome-seq150.png'
import RoboticsIMG from '../../content/img/robotics-icon.png'
import RNAseqIMG from '../../content/img/RNA-seq151.png'

const TechnologyDescription = () =>

    <div className='App-TextBox'>
        <p>VERTIS relies on an innovative in-house developed NGS library preparation platform, which is subjected to a continuous process of further improvement and optimization. This way we can offer highest flexibility to our clients in the realization of their research projects.</p>
        <p>We have implemented end-to-end quality management during the complete manufacturing process of the libraries, guaranteeing highest quality of each single NGS library preparation.</p>
        <div className='TechnologyPage-Description'>
            <h3>Technology platforms:</h3>
            <div className='TechnologyPage-Description-ImageButtonCollection'>
            <ImageButton link={'/technology/rna-seq/description'} name='RNA-Seq' image={RNAseqIMG}/>
            <ImageButton link={`/technology/genome-sequencing/description`} name='Genome Sequencing' image={GenomeSequencingIMG}/>
            <ImageButton link={`/technology/robotics`} name='Robotics' image={RoboticsIMG}/>   
            </div>
        </div>
    </div>

export default TechnologyDescription;