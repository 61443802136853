import React, { Component } from "react";
import { Icon, Divider, Accordion, List } from 'semantic-ui-react';

class DrnaSequencing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
            <h3>Differential RNA-sequencing (dRNA-seq)</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <p className='App-TextBox'>Differential RNA-seq for the annotation of transcriptional start sites according to Sharma CM et al. 2010. Nature 464:250-255.</p>
                <h3>Description</h3>
                <p className='App-TextBox'>Discrimination of primary from processed 5' ends by sequencing differential cDNA library pairs: one library from untreated bacterial RNA, and the other enriched for primary transcripts by terminator exonuclease treatment that degrades 5'PPP to 5'P.</p>
                <h4>The following steps are carried out during dRNA-seq cDNA library preparation:</h4>
                <div className='App-TextBox'>
                    <List bulleted>
                        <List.Item>Fragmentation of total RNA using ultra sound (optional)</List.Item>
                        <List.Item>Controlled poly(A) tailing of 3’-ends of the RNA fragments using poly(A) polymerase</List.Item>
                        <List.Item>Complete de- and re-phosphorylation (PNK) of RNA fragments</List.Item>
                        <List.Item>Enrichment for primary transcripts carrying 5' triphosphate (5' PPP) using Terminator 5’-phosphate dependent exonuclease (Epicentre) including TEX mock treatment</List.Item>
                        <List.Item>Ligation of a synthetic oligonucleotide adapters to 5’P ends of the RNA fragments</List.Item>
                        <List.Item>Synthesis of 1.-strand DNA using M-MLV-RNase H- reverse transcriptase and an oligo-dT</List.Item>
                        <List.Item>PCR amplification of cDNA</List.Item>
                    </List>
                </div>
                <h3>Advantage of method</h3>
                <p className='App-TextBox'>Well established and proven method which has been continuously validated over the last 10 years.</p>
            </div>
                <Divider/>
                <Accordion>
                    <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                    <Icon name='dropdown' />
                    <b>References</b>
                    </Accordion.Title>
                    <Accordion.Content active={activeRef === 1}>
                    <div className='App-References'>
                    <p>Papenfort, K. et al. 2015. Differential RNA-seq of Vibrio cholerae identifies the VqmR small RNA as a regulator of biofilm formation. <i>Proceedings of the National Academy of Sciences</i>. 112, 7 (2015), E766–E775.</p>

                    <p>Bischler, T. et al. 2015. Differential RNA-seq (dRNA-seq) for annotation of transcriptional start sites and small RNAs in Helicobacter pylori. <i>Methods</i>. 86, (2015), 89–101.</p>

                    <p>Kopf, M. et al. 2015. Variations in the non-coding transcriptome as a driver of inter-strain divergence and physiological adaptation in bacteria. <i>Scientific Reports</i>. 5, (2015), 9560.</p>

                    <p>Creecy, James P., and Tyrrell Conway. "Quantitative bacterial transcriptomics with RNA-seq." <i>Current opinion in microbiology</i> 23 (2015): 133-140.</p>

                    <p>Thomason, M. et al. 2014. Global transcriptional start site mapping using dRNA-seq reveals novel antisense RNAs in Escherichia coli. <i>Journal of Bacteriology</i>. 197, 1 (2014), JB.02096–14.</p>

                    <p>Kopf, M. et al. 2014. Comparative Analysis of the Primary Transcriptome of Synechocystis sp. PCC 6803. <i>DNA Research</i>. 21, 5 (2014), dsu018.</p>

                    <p>Kröger, Carsten, et al. "An infection-relevant transcriptomic compendium for Salmonella enterica Serovar Typhimurium." <i>Cell host {'&'} microbe</i> 14.6 (2013): 683-695.</p>

                    <p>Mitschke, Jan, et al. "Dynamics of transcriptional start site selection during nitrogen stress-induced cell differentiation in Anabaena sp. PCC7120." <i>Proceedings of the National Academy of Sciences</i> 108.50 (2011): 20130-20135.</p>

                    <p>Sharma, Cynthia M., et al. "The primary transcriptome of the major human pathogen Helicobacter pylori." <i>Nature</i> 464.7286 (2010): 250-255.</p>
                    </div>
                    </Accordion.Content>      
                </Accordion>
            </div>
        );
    }
}

export default DrnaSequencing;