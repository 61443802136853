import React from 'react';
import { Icon, List, Divider } from 'semantic-ui-react'

const TechDescription = () =>
    <div>
        <h3>Sequencing of RNA (RNA-seq)</h3>
        <Divider/>
        <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
            <p>VERTIS relies on an innovative in-house developed cDNA platform, which is subjected to a continuous process of further improvement and optimization. This way we can offer highest flexibility to our clients in the realization of their research projects.</p>
            <p>We have implemented end-to-end quality management during the complete manufacturing process of the cDNA, guaranteeing highest quality of each single cDNA preparation. We routinely perform the following quality controls during cDNA library preparation:</p>
            <div className='App-TextBox'>
                <List bulleted>
                    <List.Item>
                        Analysis of RNA quality by capillary electrophoresis
                    </List.Item>
                    <List.Item>
                        Quantification and examination of samples after each manufacturing step by means of capillary electrophoresis and qPCR
                    </List.Item>
                    <List.Item>
                        All steps during preparation of the cDNA libraries are documented and are supplied in form of a quality report.
                    </List.Item>
                </List>
            </div>
            <br/>
            <p><Icon name='chevron left'/><b>Please find here some more detailed information about our different methods for cDNA synthesis and automated library preparation</b></p>
        </div>
        </div>
    </div>

export default TechDescription;