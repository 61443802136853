import React from 'react';
import { Icon, List, Divider } from 'semantic-ui-react'

const GenomeSeqDescription = () =>
<div>
    <h3>Genome Sequencing</h3>
    <Divider/>
    <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
        <p>Our service is focused on the sequencing of microbial genomes. It covers the following topics:</p>
        <List bulleted>
        <List.Item>
            <List.Header>gDNA extraction</List.Header>
        </List.Item>
        <List.Item> 
            <List.Header>Shotgun library preparation</List.Header>
        </List.Item>
        <List.Item>
            <List.Header>Denovo or genomic re-sequencing</List.Header>
        </List.Item>
        <List.Item>
            <List.Header>ChIP-Seq</List.Header>
            <List.Description>We offer an integrated ChIP-Seq service from library construction to comprehensive data analysis</List.Description>
        </List.Item>
        <List.Item>
        <List.Header>Metagenomics</List.Header>
            <List.Description>Study of genomic content in a complex mixture of microorganisms</List.Description>
        </List.Item>
        </List>
        <p><Icon name='chevron left'/><b>Please find here some more detailed information about our different methods for cDNA synthesis and automated library preparation</b></p>
        </div>
    </div>
</div>

export default GenomeSeqDescription;