import React, { Component } from "react";
import { Divider } from 'semantic-ui-react';
import {Link} from 'react-router-dom';

class SpeciesRNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return(
            <div>
                <h3>Species enriched RNA-seq</h3>
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                    <p>Sequencing of enrichment bacterial transcripts in the background eukaryotic RNA</p>
                    <p>The analysis of bacteria which are in close relationship with eukarytotic cells, either in parasitic, commensalistic or mutualistic relationship, is a growing field of interest in connection with health and food management.</p>
                    <p>With our experience in related research fields (see <Link to={{ pathname:'/technology/rna-seq/special/dual-rna-seq', state:{activeTab: 'RNA-Seq', activeTopic: 'DualRNA-seq'}}}>dual RNA-seq</Link>, <Link to={{ pathname:'/technology/rna-seq/prokaryotic-transcriptomes/cappable-seq', state:{activeTab: 'RNA-Seq', activeTopic: 'Cappable-seq'}}}>Cappable-seg</Link>, <Link to={{ pathname:'/technology/rna-seq/prokaryotic-transcriptomes/drna-seq', state:{activeTab: 'RNA-Seq', activeTopic: 'dRNA-seq'}}}> dRNA-seq</Link>, Metatranscriptomics) we can offer specialized and customized approaches for the enrichment and sequencing of bacterial transcripts in all kinds of microbiomes (human, animals, plants, etc.).</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpeciesRNA;