import React, { Component } from 'react';
import './disclaimer-page.scss';
import { Grid, GridColumn } from 'semantic-ui-react';


class DisclaimerPage extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='DisclaimerPage'>
                <div className='App-ColumnContainer'>
                    <div className ='App-TextBox'>
                        <Grid columns={2} divided>
                            <Grid.Row>
                                <GridColumn>
                                    <h4><p>This Site is provided by:</p></h4>

                                    <p>vertis Biotechnologie i. L.<br/>
                                    Lise-Meitner-Str. 30<br/>
                                    85354 Freising<br/>
                                    Germany<br/></p>

                                    {/* <p>Phone: +49 8161 18516 11<br/>Fax: +49 8161 18516 12</p> */}

                                    <p>E-Mail: info@vertis-biotech.com<br/>Web: www.vertis-biotech.com</p>
                                </GridColumn>
                                <Grid.Column>
                                <p>Liquidator:<br/>Dr. Fritz Thümmler</p>

                                <p>Commercial court: Munich, Germany<br/>Companies register number: HRB 131415</p>

                                <p>Sales Tax ID: DE 212301309</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div className ='App-TextBox' style={{marginTop: "30px"}}>
                            <p>Terms and conditions:<br/>Although we have attempted to provide accurate information on the Site, we assume no responsibility for the accuracy of the information of third party sites. VERTIS Biotechnologie AG i. L. does not warrant the adequacy, currency, accuracy, or completeness of the information and materials of third party sites linked to or from this Site nor that the functions provided will be uninterrupted or error-free. VERTIS Biotechnologie AG i. L. expressly disclaims any liability for any errors in or omissions in the content of any third-party sites linked to or from this Site.</p>
                    </div>
                </div> 
            </div>
        );
    }
    
}

export default DisclaimerPage;