import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';

import NavBar from './components/navBar/navBar';
import BottomBar from './components/bottomBar/bottomBar';

import WelcomePage from './pages/welcome-page/welcome-page';
import ServicesPage from './pages/services-page/services-page';
import TechnologyPage from './pages/technology-page/technology-page';
import CompanyPage from './pages/company-page/company-page';
import SupportPage from './pages/support-page/support-page';
import ContactPage from './pages/contact-page/contact-page';
import DisclaimerPage from './pages/disclaimer-page/disclaimer-page';
import PrivacyPolicyPage from './pages/privacyPolicy-page/privacyPolicy-page';
import TandCPage from './pages/termsAndConditions-page/termsAndConditions-page';

import { Segment } from 'semantic-ui-react';

class App extends Component {
  render() {
    const App = () => (
      <div>
        <NavBar/>
        <div className='App-Container'>
          <div className='App-Label'>
          <Switch>
              <label path='/home'>Welcome</label>
              {/* <label path='/services'>Services</label>
              <label path='/technology'>Technology</label>
              <label path='/company'>Company Profile</label>
              <label path='/support'>Support & Downloads</label> */}
              <label path='/contact'>Contact</label>
              <label path='/disclaimer'>Disclaimer</label>
              <label path='/privacy-policy'>Privacy Policy</label>
              {/* <label path='/terms-and-conditions'>Standard Terms & Conditions of Service</label> */}
            </Switch>
          </div>
          <div className='App-Content'>
            <Switch>
              <Segment>
              <Route exact path="/" render={() => (<Redirect to="/home"/>)}/>
              <Route exact path='/home' component={WelcomePage}/>
              {/* <Route path='/services' component={ServicesPage}/>
              <Route path='/technology' component={TechnologyPage}/>
              <Route path='/company' component={CompanyPage}/>
              <Route path='/support' component={SupportPage}/> */}
              <Route path='/contact' component={ContactPage}/>
              <Route path='/disclaimer' component={DisclaimerPage}/>
              <Route path='/privacy-policy' component={PrivacyPolicyPage}/>
              {/* <Route path='/terms-and-conditions' component={TandCPage}/> */}
              </Segment>
            </Switch>
          </div>
        </div>
        <BottomBar/>
      </div>
    )
    return (
      <Switch>
        <App/>
      </Switch>
    );
  }
}

export default App;
