import React from 'react';
import { List, Grid, Icon, Segment } from 'semantic-ui-react';

const BioinfTab = () =>
    <div className='App-ColumnContainer'>
      <div className='App-TextBox'>
        <p>Since NGS generates huge quantities of data, bioinformatics is an important task in next generation sequencing projects.</p>
        <p>VERTIS provides help to their clients to extract maximum information from their experiments. We use different cutting-edge analyses pipelines, including CLC Bio's Genomics Workbench software package, for data analyses. </p>
        <p>Our bioinformatics service covers the following tasks:</p>
      </div>
      <div className='ServicesPage-BioinfoTab-BioinfoGrid'>
        <div><Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Segment>
              <h4>Trimming of Reads</h4>
              <List size='large'>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    Barcode filtering
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    Quality, poly(A)+ and adapter trimming of raw sequence data
                  </List.Content>
                </List.Item>
              </List>
              </Segment><Segment>
              <h4>RNA-Seq Analysis</h4>
              <List size='large'>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    Digital gene expression (DGE) analysis
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                  Analyses of differential gene expression
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    Identification of transcription start sites (TSSs) and processing sites (PSSs)
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                  MicroRNA analysis
                  </List.Content>
                </List.Item>
              </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
            <Segment>
              <h4>Mapping and Clustering of Reads</h4>
              <List size='large'>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    De-novo assembly of reads
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                    Mapping of reads to reference genomes
                  </List.Content>
                </List.Item>
              </List>
              </Segment><Segment>
              <h4>Genome Analysis</h4>
              <List size='large'>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                  Identification of SNPs and DIPs (deletion/insertion polymorphisms)
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                  Extraction of biding sites and identification of conserved biding motives in Chip-seq experiments 
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name='right triangle'/>
                  <List.Content>
                  Annotation of bacterial genomes (genomic re-sequencing and  de-novo sequencing)
                  </List.Content>
                </List.Item>
              </List>
              </Segment>
            </Grid.Column>
            </Grid.Row>
        </Grid></div>
      </div>
    </div>

export default BioinfTab;