import React, { Component } from "react";
import { Icon, Divider, Accordion, List, Image } from 'semantic-ui-react';
import WTSS_IMG from '../../../../../content/img/PrimedCDNA.png';

class WTSS_EU extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
                <h3>Whole Transcriptome Shotgun Sequencing (WTSS)</h3>
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                        <p>Already since 10 years, VERTIS prepares random-primed cDNA libraries in a strand specific manner.</p>
                        <p>We have developed a method which allows the ligation of 5' and 3' sequencing adapters specifically to the 5' and 3' ends of singles-stranded first strand cDNA (see schema below).</p>
                    </div>
                    <h3>Advantage of method</h3>
                    <div className='App-TextBox'>
                        <List bulleted>
                            <List.Item>
                            High efficiency, allowing the processing of low amounts of starting material
                            </List.Item>
                            <List.Item>
                            Perfect coverage over the full length of the transcripts
                            </List.Item>
                            <List.Item>
                            High strand-specificity 
                            </List.Item>
                        </List>
                    </div>
                    <div className='App-ImgContainer'>
                        <Image src={WTSS_IMG}/>
                    </div>
                </div>
                <Divider/>
                <Accordion>
                    <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                    <Icon name='dropdown'/>
                    <b>References</b>
                    </Accordion.Title>
                    <Accordion.Content active={activeRef === 1}>
                    <div className='App-References'>
                    <p>Cavalier-Smith, T. et al. 2015. Multigene phylogeny resolves deep branching of Amoebozoa. <i>Molecular Phylogenetics and Evolution</i>. 83, (2015), 293–304.</p>

                    <p>Behrend, A. et al. 2015. “The usual suspects″- analysis of transcriptome sequences reveals deviating B gene activity in C. vulgaris bud bloomers. <i>BMC Plant Biology</i>. 15, 1 (2015), 8.</p>

                    <p>Fritz, M. et al. 2015. Novel insights into RNP granules by employing the trypanosome‘s microtubule skeleton as a molecular sieve. <i>Nucleic Acids Research</i>. 43, 16 (2015), 8013–8032</p>

                    <p>Wibberg, D. et al. 2014. Transcriptome analysis of the phytopathogenic fungus Rhizoctonia solani AG1-IB 7/3/14 applying high-throughput sequencing of expressed sequence tags (ESTs). <i>Fungal Biology</i>. 118, 9-10 (2014), 800–813.</p>

                    <p>Christensen, K. and Anistoroaei, R. 2014. An American mink (Neovison vison) transcriptome. <i>Animal Genetics</i>. 45, 2 (2014), 301–303.</p>

                    <p>Posnien, N. et al. 2014. A Comprehensive Reference Transcriptome Resource for the Common House Spider Parasteatoda tepidariorum. <i>PLoS ONE</i>. 9, 8 (2014), e104885.</p>

                    <p>Taylor, A. et al. 2010. Conservation of Arabidopsis thaliana Photoperiodic Flowering Time Genes in Onion (Allium cepa L.). <i>Plant and Cell Physiology</i>. 51, 10 (2010), 1638–1647.</p>
                    </div>
                    </Accordion.Content>
                </Accordion>
            </div>
        );
    }
}

export default WTSS_EU;