import React, { Component } from "react";
import { Icon, Divider, Accordion, Image, Table } from 'semantic-ui-react';

import METHOD1_IMG from '../../../../../content/img/flcDNA_method01.png';
import METHOD2_IMG from '../../../../../content/img/flcDNA_method02.png';
import METHOD3_IMG from '../../../../../content/img/flcDNA_method03.png';

class FlcDNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
            <h3>cDNA Synthesis in full length</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>VERTIS has extensive experience in the preparation of full-length cDNA libraries. The libraries are best suited for isoform sequencing (Iso-Seq) using the PacBio or Nanopore systems, which enables sequencing of transcripts from the 5‘ end to the poly(A) tail.</p>
                    <p>We use different strategies for the preparation of FL-cDNA using oligo-dT priming of total or poly(A)+ RNA or adapter ligation to the 3‘ ends of rRNA depleted RNA.</p>
                </div>
                <div className='RnaSeq-Eukaryotic-FlcDNA-Methods'>
                    <Table celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Method 1</Table.HeaderCell>
                            <Table.HeaderCell>Method 2</Table.HeaderCell>
                            <Table.HeaderCell>Method 3</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <div className='App-ImageFont'>
                                    <p>5‘ Adatper ligation to 1.-strand cDNA</p>
                                    {/*<p><b>Starting material:</b><br/>Total or poly(A)+ RNA</p>*/}
                                    <p><b>Priming:</b><br/>oligo(dT)</p>
                                    <p><b>Advantage of method:</b><br/>
                                        Robust, good coverage also of partly degraded transcripts.<br/>
                                        No introduction of additional bases as in SMART protocol.</p>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                            <div className='App-ImageFont'>
                                <p>Oligo ligation to 5' ends of RNA</p>
                                {/*<p><b>Starting material:</b><br/>Total or poly(A)+ RNA</p>*/}
                                <p><b>Priming:</b><br/>oligo(dT)</p>
                                <p><b>Advantage of method:</b><br/>
                                    Possibility to enrich for true full-length transcripts (carrying 5‘ CAP structures)</p>
                            </div>
                            </Table.Cell>
                            <Table.Cell>
                            <div className='App-ImageFont'>
                                <p>Ligation of 3‘ adatper to 3‘ ends of RNA</p>
                                {/*<p><b>Starting material:</b><br/>ribo-depleted RNA</p>*/}
                                <p><b>Priming:</b><br/>3‘ adapter</p>
                                <p>5‘ adapters can be ligated according to method 1 or 2</p>
                                <p><b>Advantage of method:</b><br/>Possibility to also capture transcripts without poly(A) tails</p>
                            </div>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <Image src={METHOD1_IMG} centered fluid='true'/>
                            </Table.Cell>
                            <Table.Cell>
                                <Image src={METHOD2_IMG} centered fluid='true'/>
                            </Table.Cell>
                            <Table.Cell>
                                <Image src={METHOD3_IMG} centered fluid='true'/>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    </Table>
                </div>
            </div>
            <Divider/>
            <Accordion>
                <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                <Icon name='dropdown' />
                <b>References</b>
                </Accordion.Title>
                <Accordion.Content active={activeRef === 1}>
                <div className='App-References'>
                <p>Surbanovski, Nada, et al. "A highly specific microRNA-mediated mechanism silences LTR retrotransposons of strawberry." <i>The Plant Journal</i> 85.1 (2016): 70-82.</p>
                </div>
                </Accordion.Content>
            </Accordion>
        </div>
        );
    }
}

export default FlcDNA;