import React, { Component } from "react";
import { Divider, Image } from 'semantic-ui-react';

import RNAIMG from '../../../../../content/img/Quantitative-RNA-seq.png';

class MicroRNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
                <h3>Quantitative RNA-seq</h3>
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                        <p>Elimination of amplification noise by counting absolute numbers of molecules using unique molecular identifiers (UMI) as described by Islam, S. et al. 2014. <i>Nature Methods</i>. 11, 2 (2014), 163–166.</p>
                        <p>PCR is prone to introduce quantitative bias in fragment representation. So, with each PCR circle, the fragment representation bias increases accordingly. In many cases of RNA-seq projects a major goal is the analysis of differential gene expression and if many PCR cycles are used for cDNA amplification, the results of such experiments can lead to severe quantitative errors.</p>
                        <p>This will be especially important if the starting material is available in only very low concentrations, e.g. in single cell experiments.</p>
                        <p>Islam et al. showed that molecular labels (UMIs) can nearly eliminate amplification noise.</p>
                        <p>In our WTSS and sRNA cDNA libraries we have replaced the i7 barcode with random N10 sequences. As a first step of cDNA library preparation, the 3’ adapter carrying the UMI sequences is ligated to the 3’OH ends of the RNA fragments. After library sequencing and mapping the reads, the UMIs are used to distinguish between PCR duplicates.</p>
                        <p>The structure of our cDNA libraries with UMIs is shown below.</p>
                        <Image src={RNAIMG}/>
                    </div>
                </div>
        </div>
        );
    }
}

export default MicroRNA;