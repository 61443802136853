import React from 'react';
import { Divider } from 'semantic-ui-react';

const ShotgunSeq = () =>
<div>
    <h3>Chip-Seq</h3>
    <Divider/>
    <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
        <p>Preparation of NGS libraries from immunoprecipitated chromatin DNA (ChIP DNA) is similar to the preparation of libraries from gDNA. However, the amount of input DNA for ChIP-Seq libraries is often lower than for standard gDNA library construction.</p>
        <p>cost-effective solution for generating chromatin immunoprecipitation sequencing (ChIP-Seq) libraries from ChIP-derived DNA.</p>
        <p>We offer the preparation of high quality NGS libraries also from ng amounts of ChIP-seq DNA.</p>
        <p>In addition to ChIp-seq library preparation and sequencing, we offer bioinformatic extraction of biding sites and identification of conserved biding motives from the Chip-seq sequence data.</p>
        </div>
    </div>
</div>

export default ShotgunSeq;