import React from 'react';
import ImageButton from '../../../components/imageButton/imageButton'

import GenomeSequencingIMG from '../../../content/img/Genome-seq150.png'
import NgsIMG from '../../../content/img/NGS_NS150.png'
import BioinformaticsIMG from '../../../content/img/Bioinformatics150.png'
import RNAseqIMG from '../../../content/img/RNA-seq151.png'

const ServicesDescription = () =>

    <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
          <p>VERTIS is dedicated towards providing high-quality and fast turn-around time molecular biology services. We have developed state-of-the-art technologies for transcriptome analysis and are able to offer innovative and flexible solutions for your gene discovery projects.</p>
          <p>We offer a complete product line, from project consulting, nucleic acid isolation, NGS library preparation, DNA sequencing to bioinformatic sequence analyses and data mining.</p>
          <div className='ServicesPage-Description'>
            <h3>Our services cover following areas:</h3>
            <div className='ServicesPage-Description-ImageButtonCollection'>
              <ImageButton link={'/services/rna-seq'} name='RNA-Seq' image={RNAseqIMG}/>
              <ImageButton link={`/services/genome-sequencing`} name='Genome Sequencing' image={GenomeSequencingIMG}/>
              <ImageButton link={`/services/ngs`} name='NGS' image={NgsIMG}/>
              <ImageButton link={`/services/bioinformatics`} name='Bioinformatics' image={BioinformaticsIMG}/>    
            </div>
          </div>
      </div>
    </div>

export default ServicesDescription