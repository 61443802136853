import React, { Component } from 'react';
import { List, Accordion, Icon, Divider } from 'semantic-ui-react';

class SpecAppTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    } 

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return (
            <div>
                <h3>Special Applications</h3>
                <Divider/>
                <div className='App-TextBox'>
                    <p>RNA‐seq enables a wide range of specialised applications. VERTIS makes available a whole range of innovative applications to it‘s customers for comprehensive analysis of challenging and complex starting materials.</p>
                    <List bulleted>
                    <List.Item>
                        <List.Header>Dual RNA-seq</List.Header>
                        <List.Description>Quantifying mixed transcriptomes, e.g. RNA-seq analysis of host-pathogen interactions.<sup>1</sup></List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Header>Metatranscriptomics</List.Header>
                        <List.Description>Analysis of the transcriptome of environmental samples and complete communities.<sup>2</sup></List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Header>Species enriched RNA-seq</List.Header>
                        <List.Description>Sequencing of enrichment bacterial transcripts in the background eukaryotic RNA</List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Header>Quantitative RNA-seq</List.Header>
                        <List.Description>Elimination of amplification noise by counting absolute numbers of molecules using unique molecular identifiers (UMI) as described by Islam, S. et al. 2014. <i>Nature Methods</i>. 11, 2 (2014), 163–166.</List.Description>
                    </List.Item>
                    </List>
                </div>
                    <Divider/>
                    <Accordion>
                        <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                        <Icon name='dropdown'/>
                        <b>References</b>
                        </Accordion.Title>
                        <Accordion.Content active={activeRef === 1}>
                        <div className='App-References'>
                        <p>[1] Westermann, A.J. et al. "Dual RNA-seq unveils noncoding RNA functions in host-pathogen interactions." <i>Nature</i> 529, 7587 (Jan. 2016), 496–501.</p>

                        <p>[2] Bremges, Andreas, et al. "Deeply sequenced metagenome and metatranscriptome of a biogas-producing microbial community from an agricultural production-scale biogas plant." <i>GigaScience</i> 4.1 (2015): 1-6..</p>

                        <p>[2] Dyksma, Stefan, et al. "Ubiquitous Gammaproteobacteria dominate dark carbon fixation in coastal sediments." <i>The ISME journal</i> (2016).</p>

                        <p>[2] Sheibani-Tezerji, Raheleh, et al. "Transcriptome Profiling of the Endophyte Burkholderia phytofirmans PsJN Indicates Sensing of the Plant Environment and Drought Stress." <i>mBio</i> 6.5 (2015): e00621-15.</p>
                        </div>
                        </Accordion.Content>
                    </Accordion>
            </div>
        );
    }
}

export default SpecAppTab;