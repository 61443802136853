import React, { Component } from "react";
import { Divider, List } from 'semantic-ui-react';

class TermSeq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
            <h3>Sequencing of 3‘ ends of bacterial transcripts (Term-seq)</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>Term-seq enables the sequencing of 3′-ends of bacterial transcripts through the adaptor ligation site to the RNA 3′-ends.</p>
                    <p>This way, the exact 3’ end termini of bacterial tarnscripts can be identified in a highly quantitative manner</p>
                </div>
                <h3>The following steps are carried out during Term-seq cDNA library preparation:</h3>
                <div className='App-TextBox'>
                    <List bulleted>
                        <List.Item>
                        Depletion of rRNA molecules from total RNA preparations
                        </List.Item>
                        <List.Item>
                        Ligation of 5’ sequencing adapter to the 3’OH end of RNA molecules
                        </List.Item>
                        <List.Item>
                        Synthesis of 1.-strand cDNA
                        </List.Item>
                        <List.Item>
                        Fragmentation and purification of 1.-strand cDNA
                        </List.Item>
                        <List.Item>
                        Strand specific ligation of 3’ sequencing adapter to the 3’ end of single-stranded anti-sense first-strand cDNA
                        </List.Item>
                        <List.Item>
                        Selective PCR amplification of 3’ cDNA fragments
                        </List.Item>
                    </List>
                    <p>The sequence reads are in antisense orientation and pass through the 3'-ends of the RNA molecules!</p>
                </div>
            </div>
        </div>
        );
    }
}

export default TermSeq;