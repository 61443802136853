import React, { Component } from 'react';
import './welcome-page.scss';
import { Grid, Segment, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class WelcomePage extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        return (
          <div className='WelcomePage'>
            <div className='App-ColumnContainer'>
                <Grid>
                    <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment raised>
                            <h2 className ='WelcomePage-SectionHeader' style={{textAlign:"center"}}>Dear customers, interested parties and friends of vertis</h2>
                            <p className ='App-TextBox'>
                            
                            <p>I regret to inform you that we will cease operations at the end of the year 2022.</p>
                            <p>I would like to thank everyone for the good and trusting cooperation over the past years.</p>
                            Sincerely,<br/>
                            Fritz Thuemmler
                            </p>
                            {/* <p className ='App-TextBox'>
                            VERTIS is a research-oriented company in the field of functional genomics. The strength of our service is customer-oriented flexibility which is based on our innovative technology platform which we have developed in our 20-year history.</p> */}
                        </Segment>
                    </Grid.Column>
                    </Grid.Row>

                    {/* <Grid.Row columns={1} textAlign={"right"}>
                    <Grid.Column>
                        <div className ='WelcomePage-Section'>
                        <Link to={`/services/description`}>
                        <Segment raised>
                        <h2 className ='WelcomePage-SectionHeader'>Services <Icon name='wrench'/></h2>
                        <p className ='App-TextBox'>
                        We offer innovative tools and strategies for the comprehensive qualitative and quantitative analysis of bacterial and eukaryotic transcriptomes, using state-of-the-art Next Generation Sequencing (NGS) of RNA molecules (RNA-seq).</p></Segment></Link></div>
                    </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                    <Grid.Column>
                        <div className ='WelcomePage-Section'>
                        <Link to={`/technology/description`}>
                        <Segment raised>
                        <h2 className ='WelcomePage-SectionHeader'>Technology <Icon name='dna'/></h2>
                        <p className ='App-TextBox'>
                        Special emphasis of VERTIS lies in the analysis of bacterial transcriptomes. We are able to provide specialised applications like dRNA-seq, tagRNA-seq, dual RNA-seq, Cappable-seq, etc., to our customers. Our consequent use of a robotic system for NGS library preparation guarantees highly reproducible data, more consistent yields and higher quality data.</p></Segment></Link></div>
                    </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment raised>
                            <div className ='WelcomePage-ContactSection'>
                                <p className ='App-TextBox'>We offer a complete product line, ranging from project consulting, nucleic acid isolation, NGS library preparation, DNA sequencing to bioinformatic sequence analysis and data mining.</p>
                                <h2>Work with us <Icon name='arrow right'/></h2>
                                <div className ='WelcomePage-ButtonGroup'>
                                    <Button  size='big' as={Link} to='/contact'>Contact</Button>
                                    <Button  size='big' as={Link} to='/support'>Order</Button></div>
                            </div>
                        </Segment>
                    </Grid.Column>
                    </Grid.Row> */}
                </Grid>
            </div>
        </div>
        );
    }
    
}

export default WelcomePage;