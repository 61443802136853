import React, { Component } from "react";
import { Icon, Divider, Accordion } from 'semantic-ui-react';
import {Link} from 'react-router-dom'

//import MicroRNA_IMG from '../../../../../content/img/MicroRNA_Clonig_Example.png';

class MicroRNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
            <h3>cDNA Synthesis From microRNA (miRNA)</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>We have a strong background in the sequencing of miRNA - we were actually one of the first companies world wide, which offered professional miR sequencing services (see Berezikov et al. 2006, Nature Genetics 38: 1375-1377).</p>
                    <p>We use the well proven method of adapterligation of the 3‘OH and 5‘P ends of miRNA or piRNA molecules.</p>
                    <p>The method is very sensitive and we get along with only a few ng of total RNA. In addition, the use of <Link to={{
                            pathname:'/technology/rna-seq/special/quantitative-rna-seq', 
                            state:{activeTab: 'RNA-Seq', activeTopic: 'QuantitativeRNA'}}}>Unique Molecular Identifiers(UMIs)</Link> enables the elimination of amplification noise from the sequencing data..</p>
                </div>
                {/* <h4>Example: Preparation of cDNA from microRNA</h4>
                <div className='App-TextBox'>
                    <p>Small RNA was isolated from Arabidopsis leaves using the mirPremier microRNA Isolation kit from SIGMA. Then miR species in a size range of 15 - 30 nt were semi-automatically fractionated using the LabChip XT fractionation system from Caliper/PerkinElmer. Images of the quality controls of the different steps during miR cDNA synthesis are shown below:</p>
                </div>
                <div className='App-ImgContainer'><Image src={MicroRNA_IMG}/></div>
                <div className='App-TextBox'>
                    <p><br/>The combined length of the flanking sequences (Illumina sequencing adapters and polyA tail) is about 150 bases. Therefore, PCR-products containing miR sequences of  15 - 30 nt have a total length of 165 – 180 bp.</p>
                </div> */}
            </div>
            <Divider/>
            <Accordion>
                <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                <Icon name='dropdown' />
                <b>References</b>
                </Accordion.Title>
                <Accordion.Content active={activeRef === 1}>
                <div className='App-References'>
                <p>Porstner, M. et al. 2015. miR-148a promotes plasma cell differentiation and targets the germinal center transcription factors Mitf and Bach2. European Journal of Immunology. 45, 4 (2015), 1206–1215.</p>

                <p>Chandran, A. et al. 2014. The TGF-?-inducible miR-23a cluster attenuates IFN-? levels and antigen-specific cytotoxicity in human CD8+ T cells. Journal of Leukocyte Biology. 96, 4 (2014), jlb.3A0114–025R.</p>

                <p>Schulte, L. et al. 2011. Analysis of the host microRNA response to Salmonella uncovers the control of major cytokines by the let-7 family. The EMBO Journal. 30, 10 (2011), 1977–1989.</p>

                <p>Schraivogel, D. et al. 2011. CAMTA1 is a novel tumour suppressor regulated by miR-9/9* in glioblastoma stem cells. The EMBO Journal. 30, 20 (2011), 4309–4322.</p>

                <p>Brameier, M. et al. 2011. Human box C/D snoRNAs with miRNA like functions: expanding the range of regulatory RNAs. Nucleic Acids Research. 39, 2 (2011), 675–686.</p>

                <p>Wong, C. et al. 2011. MicroRNAs in the shoot apical meristem of soybean. Journal of Experimental Botany. 62, 8 (2011), 2495–2506.</p>

                <p>Aguilar, A. et al. 2010. The small RNA expression profile of the developing murine urinary and reproductive systems. FEBS Letters. 584, 21 (2010), 4426–4434.</p>

                <p>Krol, J. et al. 2010. Characterizing Light-Regulated Retinal MicroRNAs Reveals Rapid Turnover as a Common Property of Neuronal MicroRNAs. Cell. 141, 4 (2010), 618–631.</p>

                <p>Li, S. et al. 2009. Direct sequencing and expression analysis of a large number of miRNAs in Aedes aegypti and a multi-species survey of novel mosquito miRNAs. BMC Genomics. 10, 1 (2009), 581.</p>

                <p>Zhu et al. 2009. Identification of Novel Epstein-Barr Virus MicroRNA Genes from Nasopharyngeal Carcinomas. Journal of Virology. 83, 7 (2009), 3333–3341.</p>

                <p>De Wit, E. et al. 2009. Repertoire and evolution of miRNA genes in four divergent nematode species. Genome Research. 19, 11 (2009), 2064–2074.</p>

                <p>Tarasov, V. et al. 2007. Differential Regulation of microRNAs by p53 Revealed by Massively Parallel Sequencing: miR-34a is a p53 Target That Induces Apoptosis and G1-arrest. Cell Cycle. 6, 13 (2007), 1586–1593.</p>

                <p>Kloosterman, W. et al. 2006. Cloning and expression of new microRNAs from zebrafish. Nucleic Acids Research. 34, 9 (2006), 2558–2569.</p>

                <p>Berezikov, E. et al. 2006. Diversity of microRNAs in human and chimpanzee brain. Nature Genetics. 38, 12 (2006), 1375–1377.</p>

                <p>Berezikov et al. 2006. Many novel mammalian microRNA candidates identified by extensive cloning and RAKE analysis. Genome Research. 16, 10 (2006), 1289–1298.</p>
                </div>
                </Accordion.Content>
            </Accordion>
        </div>
        );
    }
}

export default MicroRNA;