import React, { Component } from "react";
import { Divider, List, Icon } from 'semantic-ui-react';

class Information extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return(
            <div>
            <h3>Eukaryotic Transcriptomics</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                   <p>VERTIS relies on an innovative in-house developed cDNA platform, which is subjected to a continuous process of further improvement and optimization. This way we can offer highest flexibility to our clients in the realization of their research projects.</p>
                   <div className='App-TextBox'>
                   <List bulleted>
                       <List.Item>
                        Whole Transcriptome Shotgun Sequencing (WTSS)
                       </List.Item>
                       <List.Item>
                        Full-Length cDNA Synthesis
                        <List.Description>
                        (e.g. for sequencing with the PacBio RS II system)
                            </List.Description>
                       </List.Item>
                       <List.Item>
                       cDNA Normalization
                        <List.Description>
                        (For equalization of gene representation in NGS libraries)
                        </List.Description>
                       </List.Item>
                       <List.Item>
                       cDNA Preparation from microRNA
                       </List.Item>
                       <List.Item>
                       Parallel Analysis of RNA Ends (PARE)
                        <List.Description>
                        (for identification of microRNA targets)
                        </List.Description>
                       </List.Item>
                       <List.Item>
                       Digital Gene Expression Analysis (DGE)
                       </List.Item>
                   </List><br/>
                   </div>
                   <p><Icon name='chevron left'/><b>Please find here some more detailed information about our different methods for cDNA synthesis and automated library preparation</b></p>
                </div>
            </div>
        </div>
        );
    }
}

export default Information;