import React, { Component } from "react";
import { Divider, Icon } from 'semantic-ui-react';

class Information extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return(
            <div>
            <h3>Bacterial Transcriptomics</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>Special emphasis of VERTIS lies in the analysis of bacterial transcriptomes. Bacteria are in the central focus of important social challenge such as infectious diseases and they are of enormous economic interest as for white biotechnology or food industry.</p>
                    <p>On the other hand, the preparation of high-quality cDNA from bacteria is highly demanding, because the bacterial mRNA is very unstable and due to the absence of 3' poly(A) tails, it's enrichment is much more challenging.</p>
                    <p>VERTIS has developed efficient techniques which allow a very accurate and deep analysis of bacterial whole transcriptomes, small regulatory RNAs (sRNome), transcription start sites (TSSs), procession sites (PSSs) and 3‘ termination sites.</p>
                    <p><Icon name='chevron left'/><b>Please find here some more detailed information about our different methods for cDNA synthesis and automated library preparation</b></p>
                </div>
            </div>
        </div>
        );
    }
}

export default Information;