import React, { Component } from 'react';
import './technology-page-rnaSeq.scss';
import { Link, Route } from 'react-router-dom';
import { Menu, Segment } from 'semantic-ui-react';

import Description from './rnaSeq-components/technology-page-rnaDescription.js';
import RNAPrepTab from './rnaSeq-components/technology-page-rnaPrepTab.js';
import RiboTab from './rnaSeq-components/technology-page-ribodepletionTab.js';
import EukarInfTab from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-information.js';
import ProkarInfTab from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-information.js';
import SpecAppTab from './rnaSeq-components/technology-page-specialApplications/specAppTab-description.js';

import EuWTSS from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-wtss.js';
import FlcDNA from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-flcDNA.js';
import Normalization from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-normalization.js';
import MicroRNA from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-microRNA.js';
import PARE from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-pare.js';
import DGE from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-dge.js';
import SingleCell from './rnaSeq-components/technology-page-eukarInfTab/eukaryoticTranscript-singleCell.js';

import ProWTSS from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-wtss.js';
import SmallRNA from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-smallRNA.js';
import DRNASeq from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-dRNASeq.js';
import CappableSeq from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-cappableSeq.js';
import TagRNASeq from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-tagRNASeq.js';
import TermSeq from './rnaSeq-components/technology-page-prokarInfTab/prokaryoticTranscript-termSeq.js';

import DualRNA from './rnaSeq-components/technology-page-specialApplications/specAppTab-dualRNA.js';
import Metatranscript from './rnaSeq-components/technology-page-specialApplications/specAppTab-metatranscript.js';
import SpeciesRNA from './rnaSeq-components/technology-page-specialApplications/specAppTab-speciesRNA.js';
import QuantitativeRNA from './rnaSeq-components/technology-page-specialApplications/specAppTab-quantitativeRNA.js';

class RnaSeq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTopic: ''
        }
    }
    
    handleTabChange = (e, {name}) => {
        this.setState({ activeTopic: name})
    }

    componentDidMount() {
        try {
            this.setState({activeTopic: this.props.location.state.activeTopic})
          } catch {
            //console.log('no props from this Route')
          }
      }

    componentWillReceiveProps(props) {
        try {
          this.setState({activeTopic: props.location.state.activeTopic})
        } catch {
          //console.log('no props from this Route')
        }
      }

    render() {
        const { activeTopic } = this.state
        
        return (
            <div className="RnaSeq">
                <Menu vertical pointing>
                    <Menu.Item>
                        <Menu.Header
                        className='App-Linkable'
                        as={ Link } 
                        to={`${this.props.match.url}/rna-preparation`} 
                        name='RNA-Preparation' 
                        active={activeTopic === 'RNA-Preparation'} 
                        onClick={this.handleTabChange}>RNA Preparation</Menu.Header>
                    </Menu.Item>
                    <Menu.Item>
                        <Menu.Header
                        className='App-Linkable'
                        as={ Link }
                        to= {{ pathname: `${this.props.match.url}/ribodepletion`, state: { activeTopic: 'Ribodepletion'}}}
                        name='Ribodepletion'
                        active={activeTopic === 'Ribodepletion'}
                        onClick={this.handleTabChange}>Ribodepletion</Menu.Header>
                    </Menu.Item>
                    <Menu.Item>
                        <Menu.Header
                        className='App-Linkable'
                        as={ Link }
                        to= {{ pathname: `${this.props.match.url}/eukaryotic-transcriptomes/description`, state: { activeTopic: 'Eukaryotic Transcriptomes'}}}
                        name='Eukaryotic Transcriptomes'
                        active={activeTopic === 'Eukaryotic Transcriptomes'}
                        onClick={this.handleTabChange}>Eukaryotic Transcriptomes</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item 
                            as={ Link } 
                            name='EuWTSS' 
                            active={activeTopic === 'EuWTSS'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/wtss`}>WTSS</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='SingleCell' 
                            active={activeTopic === 'SingleCell'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/single-cell`}>Single Cell</Menu.Item>
                            <Menu.Item
                            as={ Link } 
                            name={'FL-cDNA'} 
                            active={activeTopic === 'FL-cDNA'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/fl-cdna`}>FL-cDNA</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='Normalization' 
                            active={activeTopic === 'Normalization'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/normalization`}>Normalization</Menu.Item>
                            <Menu.Item
                            as={ Link }
                            name='Micro-RNA'
                            active={activeTopic === 'Micro-RNA'}
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/micro-rna`}>Micro-RNA</Menu.Item>
                            <Menu.Item
                            as={ Link }
                            name='PARE' 
                            active={activeTopic === 'PARE'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/pare`}>PARE</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='DGE' 
                            active={activeTopic === 'DGE'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/eukaryotic-transcriptomes/dge`}>DGE</Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                    <Menu.Item>
                        <Menu.Header as={ Link }
                            className='App-Linkable'
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/description`}
                            name='Prokaryotic Transcriptomes'
                            active={activeTopic === 'Prokaryotic Transcriptomes'}
                            onClick={this.handleTabChange}>Prokaryotic Transcriptomes</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item
                            as={ Link } 
                            name='ProWTSS' 
                            active={activeTopic === 'ProWTSS'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/wtss`}>WTSS</Menu.Item>
                            <Menu.Item
                            as={ Link } 
                            name={'Small RNA'} 
                            active={activeTopic === 'Small RNA'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/small-rna`}>Small RNA</Menu.Item>
                            <Menu.Item
                            as={ Link } 
                            name='dRNA-seq' 
                            active={activeTopic === 'dRNA-seq'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/drna-seq`}>dRNA-seq</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='tagRNA-seq' 
                            active={activeTopic === 'tagRNA-seq'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/tagRNA-seq`}>tagRNA-seq</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='Cappable-seq' 
                            active={activeTopic === 'Cappable-seq'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/cappable-seq`}>Cappable-seq</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='term-seq' 
                            active={activeTopic === 'term-seq'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/prokaryotic-transcriptomes/term-seq`}>Term-seq</Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                    <Menu.Item>
                        <Menu.Header
                        className='App-Linkable'
                        as={ Link }
                        to={`${this.props.match.url}/special/description`}
                        name='Special Applications'
                        active={activeTopic === 'Special Applications'}
                        onClick={this.handleTabChange}>Special Applications</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item 
                            as={ Link }
                            name='DualRNA-seq' 
                            active={activeTopic === 'DualRNA-seq'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/special/dual-rna-seq`}>Dual RNA-seq</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name={'Metatranscript'} 
                            active={activeTopic === 'Metatranscript'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/special/metatranscriptomics`}>Metatranscriptomics</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='SpeciesRNA' 
                            active={activeTopic === 'SpeciesRNA'} 
                            onClick={this.handleTabChange}
                            to={`${this.props.match.url}/special/species-enriched-rna-seq`}>Species enriched RNA-Seq</Menu.Item>
                            <Menu.Item 
                            as={ Link } 
                            name='QuantitativeRNA' 
                            active={activeTopic === 'QuantitativeRNA'} 
                            onClick={this.handleTabChange} 
                            to={`${this.props.match.url}/special/quantitative-rna-seq`}>Quantitative RNA-seq</Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                </Menu>
                <div className='RnaSeq-Content'>
                <Segment>
                    <Route path={`${this.props.match.path}/description`} component={Description}/>
                    <Route path={`${this.props.match.path}/rna-preparation`} component={RNAPrepTab}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/description`} component={EukarInfTab}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/description`} component={ProkarInfTab}/>
                    <Route path={`${this.props.match.path}/special/description`}  component={SpecAppTab}/>
                    <Route path={`${this.props.match.path}/ribodepletion`}  component={RiboTab}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/wtss`} component={EuWTSS}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/single-cell`} component={SingleCell}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/fl-cdna`} component={FlcDNA}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/normalization`} component={Normalization}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/micro-rna`} component={MicroRNA}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/pare`} component={PARE}/>
                    <Route path={`${this.props.match.path}/eukaryotic-transcriptomes/dge`} component={DGE}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/wtss`} component={ProWTSS}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/small-rna`} component={SmallRNA}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/drna-seq`} component={DRNASeq}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/cappable-seq`} component={CappableSeq}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/tagRNA-seq`} component={TagRNASeq}/>
                    <Route path={`${this.props.match.path}/prokaryotic-transcriptomes/term-seq`} component={TermSeq}/>
                    <Route path={`${this.props.match.path}/special/dual-rna-seq`} component={DualRNA}/>
                    <Route path={`${this.props.match.path}/special/metatranscriptomics`} component={Metatranscript}/>
                    <Route path={`${this.props.match.path}/special/species-enriched-rna-seq`} component={SpeciesRNA}/>
                    <Route path={`${this.props.match.path}/special/quantitative-rna-seq`} component={QuantitativeRNA}/>
                </Segment>
                </div>
            </div>
        );
    } 
}

export default RnaSeq;