import React, { Component } from 'react';
import './technology-page-genomeSeq.scss';
import { Link, Route } from 'react-router-dom';
import { Menu, Segment } from 'semantic-ui-react';


import Description from './genomeSeq-components/genomeSeq-description';
import ShotgunSeqTab from './genomeSeq-components/genomeSeq-shotgunSeq';
import ChipSeq from './genomeSeq-components/genomeSeq-chipSeq';
import Meta16Seq from './genomeSeq-components/genomeSeq-metagenome16s';

class GenomeSeq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTopic: ''
        }
    }
    
    handleTabChange = (e, {name}) => {
        this.setState({ activeTopic: name})
    }

    componentDidMount() {
        try {
            this.setState({activeTopic: this.props.location.state.activeTopic})
          } catch {
            //console.log('no props from this Route')
          }
      }

    componentWillReceiveProps(props) {
        try {
          this.setState({activeTopic: props.location.state.activeTopic})
        } catch {
          //console.log('no props from this Route')
        }
      }

    render() {
        const { activeTopic } = this.state
        
        return (
            <div>
            <div className='GenomeSeq'>

            <Menu pointing vertical>
                <Menu.Item  as={ Link } name='Genomic shotgun sequencing'
                to={`${this.props.match.url}/shotgun-sequencing`}
                active={activeTopic === 'Genomic shotgun sequencing'}
                onClick={this.handleTabChange}>
                    Genomic shotgun sequencing
                </Menu.Item>
                
                <Menu.Item 
                as={ Link }
                to={`${this.props.match.url}/chip-seq`}
                name='Chip-seq'
                active={activeTopic === 'Chip-seq'}
                onClick={this.handleTabChange}>Chip-seq</Menu.Item>
                
                <Menu.Item
                as={ Link }
                to={`${this.props.match.url}/metagenome-and-16s`}
                name='Metagenome16S'
                active={activeTopic === 'Metagenome16S'}
                onClick={this.handleTabChange}>Metagenome and 16S sequencing</Menu.Item>
            </Menu>
            <div className='GenomeSeq-Content'>
                <Segment>
                    <Route path={`${this.props.match.path}/description`} component={Description}/>
                    <Route path={`${this.props.match.path}/shotgun-sequencing`} component={ShotgunSeqTab}/>
                    <Route path={`${this.props.match.path}/chip-seq`} component={ChipSeq}/>
                    <Route path={`${this.props.match.path}/metagenome-and-16s`} component={Meta16Seq}/>
                </Segment>
            </div>
        </div>
        </div>
        );
    } 
}
export default GenomeSeq;