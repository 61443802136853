import React, { Component } from 'react';
import './contact-page.scss';
import { Segment, Divider } from 'semantic-ui-react';
// import Map from '../../components/map/map';
//import MapComponent from '../../components/mapComponent/mapComponent';

//import ServiceRequestForm from '../../components/serviceRequestForm/serviceRequestForm';
import ContactForm from '../../components/contactForm/contactForm';

class ContactPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        const position = [48.398164, 11.711885]
        return (
          <div className='ContactPage'>
           <div className='App-ColumnContainer'>
                <p className='App-TextBox'>Please, feel free to ask for further information or to give us some feedback.</p>
                
                <div className='ContactPage-Grid'>
                   <Segment>
                        <h3>Mail</h3>
                        <Divider/>
                        Vertis Biotechnologie AG i. L.<br/>
                        Lise-Meitner-Straße 30<br/>
                        85354 Freising<br/>
                        Germany
                    </Segment>
                    {/* <Segment>
                        <h3>Phone</h3>
                        <Divider/>
                        {"Phone: +49 (0)8161 18516 11"}<br/><br/>
                        {"Fax: +49 (0)8161 18516 12"}
                    </Segment> */}
                    <Segment>
                        <h3>Email</h3>
                        <Divider/>
                        <a href='mailto:info@vertis-biotech.com'>info@vertis-biotech.com</a><br/>
                    </Segment>
                </div>
                
                <div className='ContactPage-ContactUs-Box'>
                    <Segment className='ContactPage-ContactUs-Box'>
                        <p className='App-TextBox'>Contact us right now!</p>
                        <ContactForm/>
                    </Segment>
                </div>
                
                {/* <div>
                    <Map/>
                </div> */}

                {/*<div className='ContactPage-Map'>
                    <MapComponent 
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAOOUut6BabAeY6_WLQ7zxMc7s_KB7nFMc&callback=initMap"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `200px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>*/}
            </div>
          </div>
        );
    }
    
}

export default ContactPage;