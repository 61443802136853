import React, { Component } from "react";
import { Divider } from 'semantic-ui-react';

class RibodepletionTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTopic: ''
        }
    }

    handleTabChange = (e, {name}) => this.setState({ activeTopic: name})

    componentDidMount() {
        try {
            this.setState({activeTopic: this.props.location.state.activeTopic})
        } catch {
            //console.log('no props')
        }
    }

    render() {
        return (
            <div>
            <h3>Depletion of rRNA molecules</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>rRNA molecules can make up over 90% of the transcript of a total RNA preparation. In order to avoid high redundancy in RNA-seq data, it is first worthwhile to remove the rRNA before cDNA library production.</p>
                    <p>Until recently we successfully used the Ribo-Zero kits from Illumina, which were the golden standard for rRNA removal, but Illumina unfortunately stopped selling the kits.</p>
                    <p>Therefore, we developed our own probes and methods for rRNA removal from eukaryotic and prokaryotic RNA samples. Our method now works just as well as the Ribo-Zero Kits.</p>
                    <p>In this way we can continue to offer our RNA-seq service with the usual quality and reliability to our customers.</p>
                </div>
            </div>
            </div>
        );
    }   
}


export default RibodepletionTab;