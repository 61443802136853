import React from 'react';
import { Table, Grid, Image } from 'semantic-ui-react';
import SequencerIMG from '../../../content/img/NextSeq500_2.png'

const NGSeqTab = () =>
    <div>
      <div className='ServicesPage-NGsTab'>
        <div className='App-TextBox'>
          <p>We use the <b>Illumina NextSeq 500</b> desktop  sequencing System. It allows fast, flexible and high-throughput next-generation sequencing applications with the accuracy of Illumina SBS technology.</p>
        </div>
        <div className='ServicesPage-NGsTab-Parameters'>
        <div>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className='ServicesPage-NGsTab-Parameters-Output'>
                <h3 className='ServicesPage-NGsTab-Parameters-Label'>System Performance Parameters</h3>
                <h4 className='ServicesPage-NGsTab-Parameters-OutputOption'>High output option</h4>
                <Table celled fixed>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Read Length</Table.HeaderCell>
                      <Table.HeaderCell>Reads</Table.HeaderCell>
                      <Table.HeaderCell>Output</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>2 x 150 bp</Table.Cell>
                      <Table.Cell>2 x 400 Million</Table.Cell>
                      <Table.Cell>100-120 Gb</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2 x 75 bp</Table.Cell>
                      <Table.Cell>2 x 400 Million</Table.Cell>
                      <Table.Cell>50-60 Gb</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>1 x 75 bp</Table.Cell>
                      <Table.Cell>1 x 400 Million</Table.Cell>
                      <Table.Cell>25-30 Gb</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                </div>
                <div className='ServicesPage-NGsTab-Parameters-Output'>
              <h4 className='ServicesPage-NGsTab-Parameters-OutputOption'>Mid output option</h4>
                <Table celled size='large'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Read Length</Table.HeaderCell>
                      <Table.HeaderCell>Reads</Table.HeaderCell>
                      <Table.HeaderCell>Output</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>2 x 150 bp</Table.Cell>
                      <Table.Cell>2 x 130 Million</Table.Cell>
                      <Table.Cell>30-40 Gb</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2 x 75 bp</Table.Cell>
                      <Table.Cell>2 x 130 Million</Table.Cell>
                      <Table.Cell>16-20 Gb </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                </div>
              </Grid.Column>
              <Grid.Column>
              <div className='ServicesPage-NGsTab-Parameters-Image'>
                <Image src={SequencerIMG} size= 'large'/>
              </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </div>
        </div>
      </div>
    </div>

export default NGSeqTab;