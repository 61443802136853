import React, { Component } from 'react';
import './technology-page-robotics.scss';
import { Image } from 'semantic-ui-react';

import RoboticsIMG from '../../../content/img/robotics.png'

class Robotics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <h3>Robotics</h3>
                    <p>The use of a robotic system for NGS library preparation guarantees reproducible data, more consistent yields and higher quality data.</p>
                    <p>We utilize MICROLAB® STAR Liquid Handling Workstation (STARlet, Hamilton) for NGS library preparation and DNA/RNA purifications.</p>
                </div>
                <Image className='App-ImgContainer' src={RoboticsIMG}/>
            </div>
        );
    } 
}

export default Robotics;