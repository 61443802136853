import React from 'react';
import { List, Grid, Icon, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const RNAseqTab = () =>
<div>
  <div className='ServicesPage-RNATab'>
    <Grid divided>
      <Grid.Row columns={2}>
        <Grid.Column>
        <Header as='h3' dividing>!! Newest Developments !!</Header>
          <List size='large'>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/cappable-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Cappable-seq'}
                    }}>
                    Cappable-seq
                  </List.Header>
                <List.Description>Currently the most sensitive and robust method for the precise identification of bacterial transcription start sites (TSSs)</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/tagRNA-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'tagRNA-seq'}
                    }}>
                    tagRNA-seq
                </List.Header>
                <List.Description>A modified RNA-seq approach enabling to discriminate primary from processed 5' RNA ends</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/special/quantitative-rna-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'QuantitativeRNA'}
                    }}>
                    Quantitative RNA-seq
                  </List.Header>
                <List.Description>Elimination of amplification noise</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/term-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'term-seq'}
                    }}>
                    Term-seq
                  </List.Header>
                <List.Description>Sequencing of 3‘ ends of bacterial transcripts</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/special/species-enriched-rna-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'SpeciesRNA'}}}
                  >
                    Species enriched RNA-seq
                  </List.Header>
                <List.Description>Sequencing of bacterial transcripts in the background eukaryotic RNA</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
        <Header as='h3' dividing>Analysis of eukaryotic and prokaryotic transcriptomes</Header>
          <List size='large'>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/wtss',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'EuWTSS'}
                    }}>
                    Whole Transcriptome
                </List.Header>
                <List.Description>Strand specific sequencing from poly(A)+ or rRNA depleted RNA</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/fl-cdna',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'FL-cDNA'}
                    }}>
                    FL-cDNA
                </List.Header>
                <List.Description>Full-lenght cDNA for sequencing with the Nanopore or the PacBio system</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/normalization',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Normalization'}
                    }}>
                    cDNA Normalization
                </List.Header>
                <List.Description>For equalization of gene representation in NGS libraries</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/dge',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'DGE'}
                    }}>
                    DGE
                </List.Header>
                <List.Description>Digital Gene Expression analysis based on 3’-tag NGS</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/pare',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'PARE'}
                    }}>
                    PARE
                </List.Header>
                <List.Description>Identification of miRNA targets</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/eukaryotic-transcriptomes/micro-rna',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Micro-RNA'}
                    }}>
                    MicroRNA
                </List.Header>
                <List.Description>Comprehensive miRNA sequencing services</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/small-rna',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Small RNA'}
                    }}>
                    Small RNA
                </List.Header>
                <List.Description>Identification of bacterial regulatory sRNAs (sRNome)</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
        <Header as='h3' dividing>Identification of the structure of bacterial transcriptomes</Header>
          <List size='large'>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/drna-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'dRNA-seq'}
                    }}>
                    dRNA-seq
                </List.Header>
                <List.Description>Differential RNA-seq for annotation of transcriptional start sites</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/cappable-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Cappable-seq'}
                    }}>
                    Cappable-seq
                </List.Header>
                <List.Description>Most sensitive and robust method for the identification of bacterial transcription start sites (TSSs)</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                className='ServicesPage-Service-RNAService'
                as={Link}
                to={{
                  pathname: '/technology/rna-seq/prokaryotic-transcriptomes/tagRNA-seq',
                  state: {activeTab: 'RNA-Seq', activeTopic: 'tagRNA-seq'}
                  }}>
                  tagRNA-seq
                </List.Header>
                <List.Description>Method to discriminate primary from processed 5' RNA ends </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/prokaryotic-transcriptomes/term-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'term-seq'}
                    }}>
                    Term-seq
                  </List.Header>
                <List.Description>Sequencing of 3‘ ends of bacterial transcripts</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
        <Header as='h3' dividing>Special applications</Header>
          <List size='large'>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                className='ServicesPage-Service-RNAService'
                as={Link}
                to={{
                  pathname: '/technology/rna-seq/special/dual-rna-seq',
                  state: {activeTab: 'RNA-Seq', activeTopic: 'DualRNA-seq'}
                  }}>
                  Dual RNA-seq
                </List.Header>
                <List.Description>Quantifying mixed transcriptomes</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/special/metatranscriptomics',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'Metatranscript'}
                    }}>
                    Metatranscriptomics
                </List.Header>
                <List.Description>Analysis of environmental samples and complete communities</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/special/quantitative-rna-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'QuantitativeRNA'}
                    }}>
                    Quantitative RNA-seq
                  </List.Header>
                <List.Description>Elimination of amplification noise</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <Icon name='right triangle'/>
              <List.Content>
                <List.Header
                  className='ServicesPage-Service-RNAService'
                  as={Link}
                  to={{
                    pathname: '/technology/rna-seq/special/species-enriched-rna-seq',
                    state: {activeTab: 'RNA-Seq', activeTopic: 'SpeciesRNA'}}}
                  >
                    Species enriched RNA-seq
                  </List.Header>
                <List.Description>Sequencing of bacterial transcripts in the background eukaryotic RNA</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
</div>

export default RNAseqTab;