import React from 'react';
import { List, Divider } from 'semantic-ui-react'

const ShotgunSeq = () =>
<div>
    <h3>Genomic shotgun sequencing</h3>
    <Divider/>
    <div className='App-ColumnContainer'>
        <div className='App-TextBox'>
        <p><b>Genomic re-sequencing</b><br/>For re-sequencing of bacterial genomes, from which reference genomes are available, we usually perform Illumina sequencing of shotgun libraries with short read length (1x75 bp) which is most cost efficient.</p>
        <p>Depending on the bacterial strains, we obtain coverage of the genomes of up to 99%. Even when using longer Illumina reads (e.g. 2x150bp), this does not really improve the data.</p>
        
        <p><b>De-novo sequencing</b><br/>If we perform de-novo assembly with short Illumina reads most likely we will end up with a few thousands of contigs. To avoid this, long reads are needed to generate a genomic backbone. We use Nanopore sequencing to generate such long reads (up to and over 100,000 bp).</p>
        
        <p><b>Bioinformatics</b><br/>We offer complete bacterial genome analysis including:</p>
        <List bulleted>
            <List.Item>
                identification of SNPs and DIPs (deletion/insertion polymorphisms)
            </List.Item>
            <List.Item>
                assembly and annotation of bacterial genomes (genomic re-sequencing and de-novo sequencing)
            </List.Item>
        </List>
        </div>
    </div>
</div>

export default ShotgunSeq;