import React, { Component } from "react";
import { Icon, Divider, Accordion, List } from 'semantic-ui-react';
import {Link} from 'react-router-dom'

//import SMALLRNA_IMG from '../../../../../content/img/sRNome2.png'

class SmallRNA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
                <h3>Sequencing of bacterial regulatory small RNAs</h3>
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                        <p>Regulatory RNAs in prokaryotes are referred to as small RNA (sRNAs). The sRNAs are typically between 50 and 500 nt in length.</p>
                        <p>We offer deep sequencing of bacterial small RNomes with our innovative analysis platform: </p>
                        <div className='App-TextBox'>
                        <List bulleted>
                            <List.Item>
                            Enrichement of sRNA fraction by size fractionation of total RNA.
                            </List.Item>
                            <List.Item>
                            cDNA syntheis using our WTSS cDNA synthesis method
                            <List.Description>(as outlined in WTSS, we can use the method for the deep sequencing of sRNA independent of the structure of their 5‘ ends)</List.Description>
                            </List.Item>
                            <List.Item>
                            If sRNA with specific 5’ structures are to be detected (5‘PPP, 5‘P, capped), the cDNA can be synthesis according to our <Link to={{
                                pathname:'/technology/rna-seq/prokaryotic-transcriptomes/dRNA-seq',
                                state: {activeTab: 'Prokaryotic Transcriptomes', activeTopic: 'dRNA-seq'}
                            }}>dRNA-seq method</Link>.
                            </List.Item>
                        </List>
                        </div>
                    </div>
                </div>
                <Divider/>
                <Accordion>
                    <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                    <Icon name='dropdown' />
                    <b>References</b>
                    </Accordion.Title>
                    <Accordion.Content active={activeRef === 1}>
                    <div className='App-References'>
                    <p>Köhler, K. et al. 2015. Structural and mechanistic characterization of 6S RNA from the hyperthermophilic bacterium Aquifex aeolicus. <i>Biochimie</i>. 117, (2015), 72–86.</p>

                    <p>Madhugiri, R. et al. 2012. Small RNAs of the Bradyrhizobium/Rhodopseudomonaslineage and their analysis. <i>RNA Biology</i>. 9, 1 (2012), 47–58.</p>

                    <p>Wilms, I. et al. 2012. Deep sequencing uncovers numerous small RNAs on all four replicons of the plant pathogen Agrobacterium tumefaciens. <i>RNA Biology</i>. 9, 4 (2012), 446–57.</p>

                    <p>Chao, Y. et al. 2012. An atlas of Hfq-bound transcripts reveals 3' UTRs as a genomic reservoir of regulatory small RNAs. <i>The EMBO Journal</i>. 31, 20 (2012), 4005–4019.</p>

                    <p>Bohn, C. et al. 2010. Experimental discovery of small RNAs in Staphylococcus aureus reveals a riboregulator of central metabolism. <i>Nucleic Acids Research</i>. 38, 19 (2010), 6620–6636.</p>
                    </div>
                    </Accordion.Content>
                </Accordion>
            </div>
        );
    }
}

export default SmallRNA;