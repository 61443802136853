import React, { Component } from 'react';
import './serviceRequestForm.scss';
import { TextArea, Form, Modal, Button, Divider, Icon, Message, Checkbox } from 'semantic-ui-react';
import axios from 'axios';

const API_PATH = 'https://vertis-biotech.com/api/serviceRequestService.php';
//const API_PATH = 'https://vertis-biotech.com/api/serviceRequestService.test.php';

class ServiceRequestForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            serviceRequest: {
                name:'',
                company:'',
                department:'',
                country:'',
                zipCode: '',
                city: '',
                streetName:'',
                houseNumber:'',
                phone:'',
                email:'',
                serviceDetails:'',
                sampleDescription:''
            },
            sendDisabled: false,
            mailSent: false,
            error: null,
            submitPushed: false,
            heardFrom:[false,false,false,false]
        };
    }

    componentDidMount() {
        this.setState({
            contactForm: {
                name:'',
                company:'',
                department:'',
                country:'',
                zipCode: '',
                city: '',
                streetName:'',
                houseNumber:'',
                phone:'',
                email:'',
                serviceDetails:'',
                sampleDescription:''
            },
            sendDisabled: false,
            mailSent: false,
            error: null,
            submitPushed: false,
            heardFrom:[false,false,false,false]
        })
    }

    /*sendServiceRequest = _ => {
        const {serviceRequest} = this.state;

        fetch('mail/send-serviceRequest', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(serviceRequest)})    
        .then(res => console.log('thank you we got it!'))
        .catch(err => console.log(err))
    }*/

    handleHeardFromCheck = (e, {label}) => {
        if(label === 'Paper') {
            this.setState({
                heardFrom: this.state.heardFrom.map((checkbox, index) => {
                    if(index === 0) {
                        return !checkbox
                    } else {
                        return checkbox
                    }
                })
            });
        } else if(label === 'ResearchGate') {
            this.setState({
                heardFrom: this.state.heardFrom.map((checkbox, index) => {
                    if(index === 1) {
                        return !checkbox
                    } else {
                        return checkbox
                    }
                })
            });
        } else if(label === 'Colleague') {
            this.setState({
                heardFrom: this.state.heardFrom.map((checkbox, index) => {
                    if(index === 2) {
                        return !checkbox
                    } else {
                        return checkbox
                    }
                })
            });
        } else {
            this.setState({
                heardFrom: this.state.heardFrom.map((checkbox, index) => {
                    if(index === 3) {
                        return !checkbox
                    } else {
                        return checkbox
                    }
                })
            });
        }
    }

    handleFormSubmit = e => {
        const newFormData = this.createFormData();
        e.preventDefault();
        axios({
          method: 'post',
          url: `${API_PATH}`,
          headers: { 'content-type': 'multipart/form-data; boundary=${newFormData._boundary}' },
          data: newFormData
        })
          .then(result => {
            this.setState({
              mailSent: result.data.sent
            })
          })
          .catch(error => this.setState({ error: error.message }));
      };

      createFormData() {
        var formData = new FormData();
        formData.set('name', this.state.serviceRequest.name);
        formData.set('company', this.state.serviceRequest.company);
        formData.set('department', this.state.serviceRequest.department);
        formData.set('country', this.state.serviceRequest.country);
        formData.set('zipCode', this.state.serviceRequest.zipCode);
        formData.set('city', this.state.serviceRequest.city);
        formData.set('streetName', this.state.serviceRequest.streetName);
        formData.set('houseNumber', this.state.serviceRequest.houseNumber);
        formData.set('phone', this.state.serviceRequest.phone);
        formData.set('email', this.state.serviceRequest.email);
        formData.set('serviceDetails', this.state.serviceRequest.serviceDetails);
        formData.set('sampleDescription', this.state.serviceRequest.sampleDescription);
        formData.set('heardFrom', this.createHeardFromString());
        return formData;
    }

    validateInput = () => {
        if(
            this.state.serviceRequest.name !=='' &&
            this.state.serviceRequest.company !=='' &&
            this.state.serviceRequest.department !=='' &&
            this.state.serviceRequest.country !== '' &&
            this.state.serviceRequest.zipCode !=='' &&
            this.state.serviceRequest.city !=='' &&
            this.state.serviceRequest.streetName !=='' &&
            this.state.serviceRequest.houseNumber !=='' &&
            this.state.serviceRequest.phone !== '' &&
            this.state.serviceRequest.email !== '' &&
            this.state.serviceRequest.serviceDetails !== '' &&
            this.state.serviceRequest.sampleDescription !== '') {
                this.setState({sendDisabled: false})
        } else {
            this.setState({sendDisabled: true})
        }
    }

    createHeardFromString() {
        const currentHeardFrom = this.state.heardFrom;
        var currentString = ''
        for( let i=0; i <= currentHeardFrom.length; i++) {
            if(currentHeardFrom[i] != false) {
                if(currentString != '') {
                    currentString += ', '
                }
                if(i==0) {
                    currentString += 'Paper'
                } else if(i==1) {
                    currentString += 'ResearchGate'
                } else if(i==2) {
                    currentString += 'Colleague'
                } else if(i==3) {
                    currentString += 'Internet'
                }
            }
        }
        return currentString.slice(0,-2)
    }

    render() {
        const {serviceRequest} = this.state;
        const {sendDisabled} = this.state;
        const {submitPushed} = this.state;
        const {mailSent} = this.state;
        const {heardFrom} = this.state;
        return(
            <div>
                <Modal trigger={<Button basic size='big'><Icon name='paper plane'/> Request Form</Button>} closeIcon>
                    <Modal.Header>
                        <p>Service Request Form</p>
                    </Modal.Header>
                    <Modal.Description>
                        {mailSent ? 
                            <Form success className='ContactPage-ContactForm'>
                            <b>Please fill all fields to send us an e-mail:</b>
                            <Divider/>
                            <Form.Field disabled>
                            <label>Name *</label>
                            <input name='name' value={serviceRequest.name}/>
                        </Form.Field>
                        <Form.Field disabled>
                            <label>Company/Institution *</label>
                            <input name='company' value={serviceRequest.company}/>
                        </Form.Field>
                        <Form.Field disabled>
                            <label>Department *</label>
                            <input name='department' value={serviceRequest.department}/>
                        </Form.Field>
                        <Divider horizontal>Address</Divider>
                        <Form.Field disabled>
                            <label>Country *</label>
                            <input name='country' value={serviceRequest.country}/>
                        </Form.Field>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field disabled>
                                <label>Zip Code *</label>
                                <input name='zipCode' value={serviceRequest.zipCode}/>
                            </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field disabled>
                                <label>City *</label>
                                <input name='city' value={serviceRequest.city}/>
                            </Form.Field>
                            </div>
                        </div>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field disabled>
                                <label>Street  Name *</label>
                                <input name='streetName' value={serviceRequest.streetName}/>
                            </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field disabled>
                                <label>House Number *</label>
                                <input name='houseNumber' value={serviceRequest.houseNumber}/>
                            </Form.Field>
                            </div></div>
                        <Divider horizontal>Contact</Divider>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                                <Form.Field disabled>
                                    <label>Phone *</label>
                                    <input name='phone' value={serviceRequest.phone}/>
                                </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                                <Form.Field disabled>
                                    <label>E-Mail *</label>
                                    <input name='email' value={serviceRequest.email}/>
                                </Form.Field>
                            </div>
                        </div>
                        <Divider horizontal>Request</Divider>
                        <Form.Field disabled>
                                <h4>Customer Services Details *</h4>
                                <label>Please indicate the services you need in detail.</label>
                                <TextArea name='serviceDetails' value={serviceRequest.serviceDetails}/>
                            </Form.Field>
                            <Form.Field disabled>
                                <Divider/>
                                <h4>Sample Description *</h4>
                                <label>Please give a detailed description of your material (e.g. cells/tissues, RNA, amount/volume, concentration, source, etc.).<br/>Any known hazards assoziated with the sample must be reported.</label>
                                <TextArea 
                                    name='sampleDescription' 
                                    value={serviceRequest.sampleDescription}/>
                            </Form.Field>
                            <Form.Field disabled>
                                <label className='ContactPage-ContactForm-Message-Label'>How did you hear about us?</label>
                                <div className='ContactPage-ContactForm-Checkboxes'>
                                <Checkbox label='Paper'/>
                                <Checkbox label='ResearchGate'/>
                                <Checkbox label='Colleague'/>
                                <Checkbox label='Internet'/>
                            </div>
                        </Form.Field>
                            <div className='ContactPage-ContactForm-mailSent'>
                                <Message success header='Form Sent!' content="Thank you for your interest, we will come back to you soon!"/>
                            </div>
                            </Form> : 
                        <Form className='ContactPage-ContactForm'  as={'form'}  method='POST'>
                            <b>Please fill all fields to send us an e-mail:</b>
                            <Divider/>
                            <Form.Field>
                                <label>Name *</label>
                                <input name='name'
                                    value={serviceRequest.name}
                                    placeholder='Your Full Name'
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, name: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Company *</label>
                                <input name='company'
                                    value={serviceRequest.company}
                                    placeholder='Name Of Your Company/Institution'
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, company: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Department *</label>
                                <input 
                                    name='department'
                                    value={serviceRequest.department}
                                    placeholder='Name Of Your Department'
                                    onChange={e => {
                                        this.setState({serviceRequest: {...serviceRequest, department: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            <Divider horizontal>Address</Divider>
                            <Form.Field>
                            <label>Country *</label>
                            <input 
                                name='country'  
                                value={serviceRequest.country}
                                placeholder='Country'
                                onChange={e => {
                                    this.setState({serviceRequest: {...serviceRequest, country: e.target.value}});this.validateInput();}}/>
                        </Form.Field>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>Zip Code *</label>
                                <input name='zipCode' 
                                    value={serviceRequest.zipCode}
                                    placeholder='Zip Code'
                                    onChange={e => {
                                        this.setState({serviceRequest: {...serviceRequest, zipCode: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>City *</label>
                                <input name='city'
                                    value={serviceRequest.city}
                                    placeholder='City'
                                    onChange={e => {
                                        this.setState({serviceRequest: {...serviceRequest, city: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                        </div>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>Street Name *</label>
                                <input name='streetName' 
                                    value={serviceRequest.streetName}
                                    placeholder='Street Name'
                                    onChange={e => {
                                        this.setState({serviceRequest: {...serviceRequest, streetName: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>House Number *</label>
                                <input name='zipCode' 
                                    value={serviceRequest.houseNumber}
                                    placeholder='House Number'
                                    onChange={e => {
                                        this.setState({serviceRequest: {...serviceRequest, houseNumber: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                        </div>
                        <Divider horizontal>Contact</Divider>
                        <div className='ContactPage-ContactForm-Horizontal'>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>Phone *</label>
                                <input name='phone'
                                    value={serviceRequest.phone}
                                    placeholder='Your Phone Number'
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, phone: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                            <div className='ContactPage-ContactForm-Horizontal-Item'>
                            <Form.Field>
                                <label>Email *</label>
                                <input name='email'
                                    value={serviceRequest.email}
                                    placeholder='Your E-Mail Address'
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, email: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            </div>
                            </div>
                            <Divider horizontal>Request</Divider>
                            <Form.Field>
                                <Divider/>
                                <h4>Customer Services Details *</h4>
                                <label>Please indicate the services you need in detail.</label>
                                <TextArea 
                                    name='serviceDetails' 
                                    placeholder='Service Details...'
                                    value={serviceRequest.serviceDetails}
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, serviceDetails: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            <Form.Field>
                                <Divider/>
                                <h4>Sample Description *</h4>
                                <label>Please give a detailed description of your material (e.g. cells/tissues, RNA, amount/volume, concentration, source, etc.).<br/>Any known hazards assoziated with the sample must be reported.</label>
                                <TextArea 
                                    name='sampleDescription' 
                                    placeholder='Sample Description...'
                                    value={serviceRequest.sampleDescription}
                                    onChange={e => {this.setState({serviceRequest: {...serviceRequest, sampleDescription: e.target.value}});this.validateInput();}}/>
                            </Form.Field>
                            <Form.Field>
                            <label className='ContactPage-ContactForm-Message-Label'>How did you hear about us?</label>
                            <div className='ContactPage-ContactForm-Checkboxes'>
                            <Checkbox label='Paper' onChange={this.handleHeardFromCheck} checked={heardFrom[0]}/>
                            <Checkbox label='ResearchGate' onChange={this.handleHeardFromCheck} checked={heardFrom[1]}/>
                            <Checkbox label='Colleague' onChange={this.handleHeardFromCheck} checked={heardFrom[2]}/>
                            <Checkbox label='Internet' onChange={this.handleHeardFromCheck} checked={heardFrom[3]}/>
                            </div>
                        </Form.Field>
                            { submitPushed ? 
                                <Button loading disabled className='ContactPage-ContactForm-SendButton'/>:
                                <Button
                                    type='submit'
                                    className='ContactPage-ContactForm-SendButton'
                                    disabled={sendDisabled}
                                    onClick={this.handleFormSubmit}>
                                    Send
                                </Button>}
                                
                            </Form>}
                    </Modal.Description>
                </Modal>
            </div>
        );
    }

}

export default ServiceRequestForm;