import React, { Component } from 'react';
import './company-page.scss';
import { Divider, Header, Modal, Image } from 'semantic-ui-react';
//import LabeledButton from '../../components/labeledButton/labeledButton';
import ImageButton from '../../components/imageButton/imageButton';

import WEIHENST_IMG from '../../content/img/IZB-Weihenst.gif'
import PUBLIC_IMG from '../../content/img/papers.png'
import CAREER_IMG from '../../content/img/hand_shake.png'
import IZB_IMG from '../../content/img/vertis_IZB.png'

class CompanyPage extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        const publicationsLink = "https://scholar.google.com/scholar?hl=en&q=%22vertis+biotechnologie%22%20OR%20%22vertis+biotechnology%22%20OR%20%22vertis+biotech%22&btnG=Search&as_vis=0"
        return (
          <div className='CompanyPage'>
            <div className='App-ColumnContainer'>
                <div className ='App-TextBox'>
                    <p>Established in 2000, vertis Biotechnologie AG is a privately held company with its core competence in the field of functional genomics. It was founded as a spin-off of the Technische Universität München-Weihenstephan.</p>
                    <p>Our mission is to bring the most efficient state-of-the-art tools for analysis of the transcriptomes to the worldwide biotech community. The strength of our service is customer-oriented flexibility which is based on our innovative technology platform which we have developed in our 20-year history. It is subjected to a continuous process of further improvement and optimization and is examined in co-operations with research institutions as well as with pharmaceutical and biotech companies.</p>
                    <p>All our products and services come with excellent technical support, friendly customer service and fast delivery </p>
                </div>
                <div className='CompanyPage-Services'>
                    <Divider/>
                    <div className='CompanyPage-Services-Entries'>
                        <div className='CompanyPage-Services-EntryContainer'>
                        <ImageButton  title='Publications' noLink={true} hasRef={true} href={publicationsLink} image={PUBLIC_IMG} company={true}/>
                        <h4>Publications</h4>
                        </div>
                        <Modal trigger={<div><a><ImageButton title='Career' noLink={true} image={CAREER_IMG} company={true}/></a><h4>Career</h4></div>} closeIcon>
                            <Header icon='handshake outline' content='Career' />
                            <Modal.Content>
                                <div className='App-ColumnContainer'>
                                    <div className='App-TextBox'>
                                        <p>Vertis Biotechnologie AG is a research-orientated company in the field of functional genomics. The development and use of new technologies is the company's strength. This strength is closely associated with the ability and knowledge of our team members.</p>
                                        <p>Vertis Biotechnologie AG offers a research orientated surrounding at an attractive location. On our continuous enlargement we are looking for motivated, well trained employees to strengthen our team.</p>
                                        <p>To apply for a job at Vertis Biotechnologie AG send your curriculum vitae to Dr. Fritz Thümmler, CEO. We look forward to hearing from you.</p>
                                    </div>
                                    <p className='CompanyPage-Career-Positions'>Currently we have no open positions.</p>
                                    
                                </div>
                            </Modal.Content>
                        </Modal>
                        <Modal trigger={<div><a><ImageButton title='Weihenstephan' noLink={true} image={IZB_IMG} company={true}/></a><h4>Weihenstephan</h4></div>} closeIcon>
                            <Header icon='book' content='Incubator and Innovation Centre Weihenstephan' />
                            <Modal.Content>
                                <div className='App-ColumnContainer'>
                                    <div className='App-TextBox'>
                                        <p>Offices and laboratories of VERTIS are accommodated within the Innovation Center for Biotechnology (<a href='https://www.izb-online.de/en/home.html' target='_blank' rel="noopener noreferrer">IZB</a>) in Freising-Weihenstephan, which is well situated in the neighborhood of the Technical University of Munich life science campus.</p>
                                        <p>The Incubator and Innovation Center Weihenstephan was founded by the government of Bavaria to offer innovative start-ups excellent working conditions.</p>
                                        <p>With it's network of recognized research establishments and the research campus of the Technical University (TU) of Munich, the location Freising-Weihenstephan has established itself as a competence cluster for life sciences in Europe. </p>
                                    </div>
                                <Image className='App-ImgContainer' src={WEIHENST_IMG}/>
                                </div>
                            </Modal.Content>
                        </Modal>
                    </div>
                </div>
            </div>
          </div>
        );
    }
    
}

export default CompanyPage;