import React from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom'

const GenomeSeqTab = () =>

  <div className='App-ColumnContainer'>
    <h3>Genome sequencing services:</h3>
    <List bulleted size='large'>
      <List.Item>
        <List.Header as={Link} to={{
                    pathname: '/technology/genome-sequencing/description',
                    state: {showsDescription: true}
                    }} className='App-Linkable-Main'>gDNA extraction</List.Header>
      </List.Item>
      <List.Item> 
        <List.Header as={Link} to={{
                    pathname: '/technology/genome-sequencing/shotgun-sequencing',
                    state: {activeTopic: 'Genomic shotgun sequencing', showsDescription: true}
                    }} className='App-Linkable-Main'>Shot-gun library preparation</List.Header>
      </List.Item>
      <List.Item>
        <List.Header as={Link} to={{
                    pathname: '/technology/genome-sequencing/shotgun-sequencing',
                    state: {activeTopic: 'Genomic shotgun sequencing', showsDescription: true}
                    }} className='App-Linkable-Main'>De-novo or genomic re-sequencing</List.Header>
      </List.Item>
      <List.Item>
        <List.Header as={Link} to={{
                    pathname: '/technology/genome-sequencing/chip-seq',
                    state: {activeTopic: 'Chip-seq', showsDescription: true}
                    }} className='App-Linkable-Main'>ChIP-Seq</List.Header>
        <List.Description>We offer an integrated ChIP-Seq service from library construction to comprehensive data analysis</List.Description>
      </List.Item>
      <List.Item>
      <List.Header as={Link} to={{
                    pathname: '/technology/genome-sequencing/metagenome-and-16s',
                    state: {activeTopic: 'MetagenomeAnd16s', showsDescription: true}
                    }} className='App-Linkable-Main'>Metagenomics</List.Header>
        <List.Description>Study of genomic content in a complex mixture of microorganisms</List.Description>
      </List.Item>
    </List>
  </div>

export default GenomeSeqTab;