import React, { Component } from "react";
import { Icon, Divider, Accordion, Image } from 'semantic-ui-react';

import Normalization_IMG from '../../../../../content/img/norm2.PNG';

class Normalization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        const {activeRef} = this.state
        return(
            <div>
            <h3>Equalization of Gene Representation</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>Expression of genes varies from a few to several thousand copies (mRNA molecules) in certain cell types. This high range of difference in gene representation makes it difficult to discover rare mRNAs even with the new ultra-high-throughput sequencing technologies, especially when using PacBio or Nanopore sequencing. To reduce high redundancy in cDNA populations, the representation of each sequence has to be equalized.</p>
                    <p>VERTIS offers an extremely well proven and highly efficient cDNA normalization using a kinetic denaturation-reassociation technique as outlined in the schema below. Already after one round of normalization nearly equal representation of each gene is achieved and a typical normalized cDNA library has a greater than 100-fold reduction in highly abundant clones, such as beta-actin.</p>
                    <p>Normalized cDNA libraries are very useful for sequencing with the Illumina MiSeq system. The MiSeq allows read lengths of up to 2x300 bp, but the data output is restricted to about 25 million reads per run. Therefore, the use of cDNA libraries with reduced redundancy can substantially increase sequence coverage per MiSeq sequencing run.</p>
                </div>
                <div className='App-ImgContainer'>
                    <Image src={Normalization_IMG}/>
                </div>
            </div>
            <Divider/>
            <Accordion>
                <Accordion.Title active={activeRef === 1} index={1} onClick={this.handleRefClick}>
                <Icon name='dropdown' />
                <b>References</b>
                </Accordion.Title>
                <Accordion.Content active={activeRef === 1}>
                <div className='App-References'>
                <p>Wibberg, Daniel, et al. "Genome analysis of the sugar beet pathogen Rhizoctonia solani AG2-2IIIB revealed high numbers in secreted proteins and cell wall degrading enzymes." <i>BMC genomics</i> 17.1 (2016): 1.</p>

                <p>Pauli, Martina, et al. "De novo assembly of the dual transcriptomes of a polymorphic raptor species and its malarial parasite." <i>BMC genomics</i> 16.1 (2015): 1.</p>

                <p>Lin, Ya-Fen, et al. "A comprehensive set of transcript sequences of the heavy metal hyperaccumulator Noccaea caerulescens." <i>Front Plant Sci</i> 5 (2014): 261.</p>
                </div>
                </Accordion.Content>
            </Accordion>
        </div>
        );
    }
}

export default Normalization;