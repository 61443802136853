import React from 'react';
import {  List, Divider } from 'semantic-ui-react';

const RNAPrepTab = () =>
    <div>
        <h3>RNA preparation</h3>
        <Divider/>
        <div className='App-TextBox'>
            
            <p>Make use of our over 20 years of experience in the isolation of high quality RNA from your valuable and challenging starting materials.</p>
            <p>We have establishes a portfolio of well tested protocols for:</p>
            <div className='App-TextBox'>
                <List bulleted>
                    <List.Item>
                    Isolation of total RNA from a wide variety of starting materials (animals, plants, algae, fungi, protists, bacteria)
                    </List.Item>
                    <List.Item>
                    Isolation of small non-coding RNA, especially miRNA
                    </List.Item>
                    <List.Item>
                    RNA Isolation from mixed samples (e.g. infected tissues)
                    </List.Item>
                    <List.Item>
                    RNA Isolation from environmental samples (e.g. stuhl, sea water, glacial ice, etc.)
                    </List.Item>
                    <List.Item>
                    Isolation of total RNA from FFPE samples
                    </List.Item>
                </List>    
            </div>
            <br/>
            <p>Each RNA preparation will be quality checked on a Shimadzu MultiNA microchip electrophoresis system, by UV/Vis spectroscopy and quantified by fluorescence (Qubit)</p>
        {/*<div className='TechnologyPage-RNATab-Strategies'>
            <h3>Enrichment strategies for different RNA species</h3>

            <div className='TechnologyPage-RNATable'>
            <span className='TechnologyPage-RNATable-Label'><h4>Eukaryotic RNA</h4></span>
            <div><Table celled size='large'>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>RNA species</Table.HeaderCell>
                    <Table.HeaderCell>Enrichment strategy</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Poly(A)+</Table.Cell>
                        <Table.Cell>Oligo(dT) chromatography</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Full-length mRNA</Table.Cell>
                        <Table.Cell>CAP selection (enzymatic)</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Small non-coding RNA (sncRNA)</Table.Cell>
                        <Table.Cell>Selective elution from silica gel ({'<'} 200 nt) </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>MicroRNA</Table.Cell>
                        <Table.Cell>Size fractionation (15 -30 nt) with an automated fractionation system</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>rRNA depleted total RNA</Table.Cell>
                        <Table.Cell>Removal or rRNA molecules with subtractive hybridization (Ribo-Zero)</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table></div>
            </div>

            <div className='TechnologyPage-RNATable'>
            <span className='TechnologyPage-RNATable-Label'><h4>Prokaryotic RNA</h4></span>
                <div><Table celled size='large'>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>RNA species</Table.HeaderCell>
                        <Table.HeaderCell>Enrichment strategy</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>5'PPP primary transcripts</Table.Cell>
                            <Table.Cell>Enzymatic selection of 5'PPP ends</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Small non-coding RNA (sncRNA)</Table.Cell>
                            <Table.Cell>Selective elution from silica gel ({'<'} 200 nt)</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> rRNA depleted total RNA</Table.Cell>
                            <Table.Cell>Removal or rRNA molecules with subtractive hybridization (Ribo-Zero)</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table></div>
            </div>
        </div>*/}
    </div>
    </div>

export default RNAPrepTab;