import React, { Component } from "react";
import { Divider, List, Image } from 'semantic-ui-react';

import WTSS_IMG from '../../../../../content/img/Bacteria-WTSS.png'

class WTSS_Pro extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
            <h3>Bacterial Whole Transcriptome Shotgun Sequencing</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>VERTIS has developed a highly efficient method which allows a very accurate and deep analysis of bacterial whole transcriptomes including small regulatory RNAs (sRNA).</p>
                    <p>It relies on the ligation of the 3‘ sequencing adapter to the 3‘OH ends of RNA fragments or sRNA molecules. After 1. strand cDNA synthesis, the 5‘ sequencing adapter is ligated to the 3‘OH ends of the single stranded, antisense 1.-strand cDNA (see schema below).</p>
                </div>
                <h3>Advantage of method</h3>
                <div className='App-TextBox'>
                    <List bulleted>
                        <List.Item>
                        High strand specificity
                        </List.Item>
                        <List.Item>
                        Capturing of all RNA species (primary, processed and regulatory sRNA)
                        </List.Item>
                        <List.Item>
                        Complete capturing of 3‘ and 5‘ ends of the transcripts
                        </List.Item>
                        <List.Item>
                        Capturing of 5‘ ends of transcripts independent of 5‘ structur of RNAs (5‘P, 5‘PP, 5‘PPP, etc.)
                        </List.Item>
                        <List.Item>
                        Optimum coverage of transcripts over their entire length
                        </List.Item>
                        <List.Item>
                        The use of Unique Molecular Identifiers (UMIs) enables the elimination of amplification noise from the sequencing data.
                        </List.Item>
                    </List>
                </div>
                <Image className='App-ImgContainer' src={WTSS_IMG}/>
            </div>
        </div>
        );
    }
}

export default WTSS_Pro;