import React, { Component } from 'react';
import './termsAndConditions-page.scss';

class TandCPage extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        return (
          <div className='TandCPage'>
            <div className='App-ColumnContainer'>
                <div className ='App-TextBox'>
                    <p>The NGS libraries will be created with state of the art technology. The degree of success of any procedure is highly dependent on the nature and quality of the starting material.</p>
                    <p>If any potential sample concerns should become apparent before or during the procedure, VERTIS i. L. will inform the customer immediately. The customer will then make new material available to VERTIS i. L.. The additional costs arising in such a case shall be borne by the customer. The time schedule shall be postponed accordingly.</p>
                    <p>The material, from which the library will be created, is to be delivered to VERTIS i. L. by the customer. The material has to be shipped in accordance with the specification made by VERTIS i. L.. By default, the material must be stored on dry ice at a temperature of minus 70°C or less until being delivered to the VERTIS i. L. laboratory, and must still be frozen upon its delivery.</p>
                    <p>VERTIS i. L. shall assume no further liability for the utility of the library for the purposes intended by the customer. In particular, VERTIS i. L. does not provide any guarantee that the library holds the genes that the customer intends to isolate. </p>
                    <p>VERTIS i. L. will only use the material from the customer for the creation of the library. It will not transfer or sell the material to third parties, and it will turn over and transfer ownership of the library created to the customer. VERTIS i. L. shall have no rights of utilization of results that stem exclusively from the use of the library created for the customer.</p>
                    <p>VERTIS i. L. guarantees the strict confidentiality of all data and information resulting from this project. All data, results and materials remain the sole property of the customer and VERTIS i. L. will not acquire any intellectual rights resulting from information or materials generated as a result of this project.</p>
                    <p>Terms and conditions of payment: payment is to be made within fourteen days of receipt of the library and invoice. The prices listed in the offer are in EURO and are exclusive VAT, which will be added to orders from Germany. Prices are exclusive of delivery, which will be charged at cost. </p>
                    <p>All payments are to be made to the VERTIS i. L. bank account at HypoVereinsbank, Account no. 4062531 bank code no. 70021180.</p>
                    <p>VERTIS i. L. shall accept no liability for the consequences of any mishandling of the library by the customer. The customer undertakes to observe the gene technology laws in effect in the respective country when using the library in the country. VERTIS i. L. assumes no liability in this respect. The customer shall indemnify VERTIS i. L. of all claims, claims for damages, etc. from third parties, made in connection with the use of the library.</p>
                    <p>The library is exclusively for use in laboratories. It must not be employed for uses in or on people and is not suitable for diagnostic purposes, as it has neither been tested for such purposes by VERTIS i. L., nor is it designed for such uses.</p>
                    <p>The laws of the Federal Republic of Germany shall apply. The exclusive place of jurisdiction shall be the Munich Local Court 1 (Landgericht München I).</p>
                </div>
            </div>
        </div>
        );
    }
    
}

export default TandCPage;