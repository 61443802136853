import React, { Component } from "react";
import { Divider, Image, List } from 'semantic-ui-react';

import DEG_IMG from '../../../../../content/img/DEG120.png';

class PARE extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
            <h3> 3’-tag Digital Gene Expression (DGE)</h3>
            <Divider/>
            <div className='App-ColumnContainer'>
                <div className='App-TextBox'>
                    <p>Generation of genome wide expression profiles using sequencing of 3’-tags of the transcripts has the potential to replace microarrays as the method for transcriptome profiling.</p>
                    <p>The major advantages of DGE over microchip-based approaches are:</p>
                    <div className='App-TextBox'>
                    <List bulleted>
                        <List.Item>
                        increase of the dynamic range of analyses (5 orders of magnitude)
                        </List.Item>
                        <List.Item>
                        ability to detect novel exons  (truly genome wide expression profiling) 
                        </List.Item>
                        <List.Item>
                        no demand for a priori sequence information (gene expression in any species) 
                        </List.Item>
                        <List.Item>
                        generates expression data plus sequence information at the same time (parallel detection of SNPs which can be used for genotyping experiments) 
                        </List.Item>
                        <List.Item>
                        no false positives
                        </List.Item>
                    </List><br/>
                    </div>
                    <p>We offer sequencing of <b>3'-fragment cDNA</b> which starts 100 - 200 bp upstream of the polyadenylation sites of the transcripts.</p>
                    <p>Because each transcript is represented by only a single tag, 3'-fragment libraries are substantially <b>less complex</b> than standard RNA-Seq libraries, allowing useful results with <b>fewer reads per sample</b> (about 10 million reads per transcriptome), thus making 3'DGA much more cost efficient.</p>
                </div>
                <Image className='App-ImgContainer' src={DEG_IMG} size='medium'/>        
            </div>
        </div>
        );
    }
}

export default PARE;