import React, { Component } from "react";
import { Divider, List, Image } from 'semantic-ui-react';
import {Link} from 'react-router-dom'

import CAPPABLE_COLI_IMG from '../../../../../content/img/Cappable-seq_Ecoli.png'
import CAPPABLE_RHIZ_IMG from '../../../../../content/img/Cappable-seq_Rhizobium.png'

class PARE extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
                <h3>
                Cappable-seq for the precise identification of transcription start sites (TSSs)
                <Divider/>
                </h3>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                            <p>In close cooperation with New England Biolabs, Inc. (NEB), VERTIS is able to make the new Cappable-seq method available to its customers. The method was developed by Laurence Ettwiller and Ira Schildkraut (Ettwiller, L. et al. 2016. BMC Genomics. 17, 1 (2016), 199).</p>
                            <span style={{fontWeight: 'bold', paddingTop:'-10px'}}>Cappable-seq is currently the most sensitive and robust method for the precise identification of bacterial transcription start sites (TSSs).</span>
                        </div>
                        <h3>Description</h3>
                        <div className='App-TextBox'>
                            <p>The bacterial primary transcripts carry a distinctive 5' triphosphorylated end that distinguishes these transcripts from all other RNA species. The currently available methods do not use targeted enrichment for the 5' end of primary transcripts, but rather attempt to deplete non-targeted RNA, like 5'-phosphate-dependent exonuclease (TEX) treatment of RNA (dRNA-seq).</p>
                            <p>Cappable-seq directly targets the 5' end of primary transcripts, enabling determination of transcription start sites at single base resolution. This is achieved by labelling the 5' triphosphorylated end of RNA with a biotin derivate.</p>
                        </div>
                        <h3>The following steps are carried out during Cappable-seq cDNA library preparation:</h3>
                        <div className='App-TextBox'>
                            <div className='App-TextBox'>
                            <List bulleted>
                                <List.Item>Capping of the 5' triphosphorylated RNA with 3'- desthiobiotin-TEG-guanosine 5' triphosphate (DTBGTP) (NEB) using the vaccinia capping enzyme (VCE) (NEB) for reversible binding of biotinylatid RNA species to streptavidin</List.Item>
                                <List.Item>Capturing of biotinylated RNA species on streptavidin beads and elution to obtain the 5' fragment of the primary transcripts</List.Item>
                                <List.Item>Enzymatic conversion of 5' triphosphate (5'PPP) to 5' monophosphate (5'P)</List.Item>
                                <List.Item>Ligation of an RNA adapter to the formed 5'P of primary transcripts</List.Item>
                            </List><br/>
                            </div>
                            <p>We actually have increased the reliability of Cappable-seq by using a <Link to={{
                            pathname: '/technology/rna-seq/prokaryotic-transcriptomes/tagRNA-seq',
                            state: {activeTab: 'Prokaryotic Transcriptomes', activeTopic: 'tagRNA-seq'}
                        }}>modified tagRNA-seq method</Link> for cDNA library preparation. It is based on the differential ligation of two distinct sequence tags to residual 5'P-ends in the Cappable-enriched RNA and to the 5’PPP ends of primary transcripts after conversion to 5’P.</p>
                        </div>
                        <h3>Advantage of method</h3>
                        <div className='App-TextBox'>
                            <p>The method directly targets 5'PPP in total RNA preparations and there is no need the perform rRNA depletion beforehand. Therefore, there are no restrictions towards organisms, for which no feasible RNA depletion kit is available.</p>
                            <p>As a consequence, Cappable-seq has a broad range of applications, offering the ability to investigate the triphosphorylated population of RNA molecules that would otherwise be masked by the overwhelming majority of their processed counterparts. </p>
                        </div>
                    </div>

                    <div className='App-ColumnContainer'>
                        <h3>Example</h3>
                        <p className='App-TextBox'>Comparative mapping of sequence reads obtained from Cappable-seq (above panel) and from whole transcriptome cDNA libraries (lower panel).</p>
                        <div className='App-ImageFont' align-content='center'>
                            <span>Escherichia coli</span>
                            <Image src={CAPPABLE_COLI_IMG}/>
                            <Divider/>
                            <span>Rhizobium leguminosarum</span>
                            <Image src={CAPPABLE_RHIZ_IMG}/>
                        </div>
                    </div>
            </div>
        );
    }
}

export default PARE;