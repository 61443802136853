import React, { Component } from "react";
import { Divider, } from 'semantic-ui-react';
import {Link} from 'react-router-dom';

class Metatranscript extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRef: 0
        }
    }

    handleRefClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeRef } = this.state
        const newIndex = activeRef === index ? -1 : index
        this.setState({activeRef: newIndex})
    }

    render() {
        //const {activeRef} = this.state
        return(
            <div>
                <h3>Metatranscriptomics</h3>           
                <Divider/>
                <div className='App-ColumnContainer'>
                    <div className='App-TextBox'>
                        <p>Analysis of the transcriptome of environmental samples and complete communities.</p>
                        <p>In numerous projects we could already demonstrate our capabilities in sequencing of metatranscritomes from challenging environmental samples.</p>
                        <p>The key to our success was the use of our highly efficient methods for <Link to={{
                        pathname: '/technology/rna-seq/rna-preparation',
                        state: {activeTab: 'RNA-Preparation'}
                        }}>isolating RNA</Link> and synthesizing cDNA.</p>
                        <p>We also offer sequencing of 16S rRNA in order to screen for living organisms in the microbiome samples.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Metatranscript;