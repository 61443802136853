import React, {Component} from "react";
import './bottomBar.scss';
import { Link } from 'react-router-dom';

class BottomBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <div className='BottomBar'>
                <Link className='BottomBar-Item' to='/contact'>Contact</Link>
                <Link className='BottomBar-Item' to='/disclaimer'>Disclaimer</Link>
                <Link className='BottomBar-Item' to='/privacy-policy'>Privacy Policy</Link>
                {/* <Link className='BottomBar-Item' to='/terms-and-conditions'>Terms {'&'} Conditions</Link> */}
            </div>
        );
    }
}

export default BottomBar;