import React from "react";
import './imageButton.scss'
import { Link } from 'react-router-dom';
function ImageButton(props) {

    if(props.noLink === true) {
        if(props.hasRef === true) {
            //links outside of website
            if(props.company === true) { 
                return (
                    <div className='ImageButton-Company'>
                        <a href={props.href} target='_blank' rel="noopener noreferrer">
                        {/*<div className='ImageButton-Txt'><span>{props.title}</span></div>*/}
                        <img className='ImageButton-Img-Company' src={props.image} alt=""/>
                        </a>
                    </div>
                ); 
                } else {
                return (
                    <div className='ImageButton'>
                        <a href={props.href} target='_blank' rel="noopener noreferrer">
                        {/*<div className='ImageButton-Txt'><span>{props.title}</span></div>*/}
                        <img className='ImageButton-Img' src={props.image} alt=""/>
                        </a>
                    </div>
                );}
        } else {
            //no link
            if(props.company === true) { 
            return (
                <div className='ImageButton-Company'>
                    {/*<div className='ImageButton-Txt'><span>{props.title}</span></div>*/}
                    <img className='ImageButton-Img-Company' src={props.image} alt=""/>
                </div>
            ); 
            } else {
                //no link
            return (
                <div className='ImageButton'>
                    {/*<div className='ImageButton-Txt'><span>{props.title}</span></div>*/}
                    <img className='ImageButton-Img' src={props.image} alt=""/>
                </div>
            );
            } 
        }  
    } else {
        //links inside of website
        if(props.company === true) {
        return (
            <div className='ImageButton'>
                <Link to={{
                    pathname: props.link,
                    state: {activeTab:props.name, showsDescription: true}
                    }}>
                    <img className='ImageButton-Img-Company' src={props.image} alt=""/>
                </Link>
            </div>);
        } else {
        return (
            <div className='ImageButton'>
                <Link to={{
                    pathname: props.link,
                    state: {activeTab:props.name, showsDescription: true}
                    }}>
                    <img className='ImageButton-Img' src={props.image} alt=""/>
                </Link>
            </div>);
        }
    }
}


export default ImageButton;