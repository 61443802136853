import React, { Component } from 'react';
import './technology-page.scss';
import { Link, Route } from 'react-router-dom';
import { Button, Menu, Segment } from 'semantic-ui-react';

import RNASeq from './technology-page-rnaSeq/technology-page-rnaSeq';
import GenomeSeq from './technology-page-genomeSeq/technology-page-genomeSeq';
import Robotics from './technology-page-robotics/technology-page-robotics';
import Description from './technology-page-description'

class TechnologyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '',
            showsDescription: true
        }
    }

    handleTabChange = (e, {name}) => {
      this.setState({ activeTab: name})
  }

  componentDidMount() {
    if(window.location.pathname === '/technology/description') {
      this.setState({showsDescription: false})
    } else {
      this.setState({showsDescription: true})
    }
  }

    componentWillReceiveProps(props) {
        try { 
          this.setState({activeTab: props.location.state.activeTab, showsDescription: props.location.state.showsDescription})
        } catch {
          //console.log('no props from this Route')
        }
      }

    render() {
      const {activeTab} = this.state;
      const {showsDescription} = this.state;
        return (
            <div className="TechnologyPage">
                <div className='App-ColumnContainer'>
                {showsDescription ?
                  <Menu pointing secondary>
                    <Menu.Item  
                      as={ Link } 
                      to={'/technology/rna-seq/description'} 
                      name='RNA-Seq' 
                      active={activeTab === 'RNA-Seq'}
                      onClick={this.handleTabChange}>RNA-seq</Menu.Item>
                    <Menu.Item
                      as={ Link } 
                      to={'/technology/genome-sequencing/description'} 
                      name='Genome Sequencing' 
                      active={activeTab === 'Genome Sequencing'}
                      onClick={this.handleTabChange}>Genome Seq</Menu.Item>
                    <Menu.Item
                      as={ Link } 
                      to={'/technology/robotics'} 
                      name='Robotics'
                      active={activeTab === 'Robotics'}
                      onClick={this.handleTabChange}>Robotics</Menu.Item>
                  </Menu> : <div></div>}
                  <Segment basic>
                      <Route path={`/technology/description`} component={Description}/>
                      <Route path={`/technology/rna-seq`} component={RNASeq}/>
                      <Route path={`/technology/genome-sequencing`} component={GenomeSeq}/>
                      <Route path={`/technology/robotics`} component={Robotics}/>
                  </Segment>
                  <div className='TechnologyPage-Contact-Container'>
                    <span>for more information</span>
                    <Link to='/contact'><Button>Contact Us</Button></Link>
                  </div>
                </div>
            </div>
        );
    } 
}

export default TechnologyPage;